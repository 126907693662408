import React, { useState, useEffect } from 'react';
import { Pagination } from "antd";
import { Button } from '@mui/material';
import Card from '../../../components/Card';
import useAxios from "../../../api/useAxios";
import { RotateCircleLoading } from 'react-loadingg';
import { CloseOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';

const Kurslar = () => {
  const api = useAxios()
  const [open, setOpen] = useState(false);
  let type = window.localStorage.getItem('maktab')
  const [ activv, setActiv] = useState("")
  const [mode] = useState(window.localStorage.getItem('maktab'));
  const num = mode === "1" ? "#F17120" 
    : mode === "2" ? "#0BA7BF" 
    : mode === "3" ? "#0BA7BF" 
    : "#1D794B";
  const handlerClick = (e)=>{
    open ? setOpen(!true): setOpen(true);
  }
  const [levels, setLevels] = useState([])
  const getLevels = () => {
    api.get(`/level/`)
    .then((r)=>{
      // console.log(r.data.data)
      setLevels(r.data.data)
    })
  }
  const [categories, setCategories] = useState([])
  const getCategories = () => {
    api.get(`/category/`)
    .then((r)=>{
      // console.log(r.data.data)
      setCategories(r.data.data)
    })
  }
  const [status, setStatus] = useState([])
  const getStatus = () => {
    api.get(`/course-status/`)
    .then((r)=>{
      // console.log(r.data.data)
      setStatus(r.data.data)
    })
  }
  const [all, setAll] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [search, setSearch] = useState("");
  const [search_status, setSearch_status] = useState("");
  const [level_status, setLevel_status] = useState("");
  const [category_status, setCategory_status] = useState("Barchasi");
  const [active_status, setActive_status] = useState("");
  const [page, setPage] = useState(1);
  const filterUrl = `/courses/?search=${search_status}&level=${level_status}&category=${activv}&status=${active_status}&page=${page}`;
  const [filter_click , setFilter_click] = useState(false);

  const Filter = () =>{
    api.get(filterUrl)
    .then((res)=>{
      // console.log(filterUrl)
      // console.log(res.data.data.pagination)
      setFilter_click(true)
      setAll(res.data.data?.result)
      // console.log(res.data.data?.result)
      setPagination(res.data.data.pagination)
      setTimeout(() => {
        setFilter_click(false)
      }, 500);
    })
  }
  useEffect(()=>{
    getLevels()
    getStatus()
    getCategories()
    Filter()
  },[])

  useEffect(()=>{
    Filter()
  },[activv,active_status,level_status,search_status,page])
  return ( 
    <div className='kurslar__wrapper'>
      <div className="kurslar">
        
        <div  className={ open ? "kurslar__bar kurslar__bar-block": "kurslar__bar"}>
          <button className='kurslar__filter' onClick={handlerClick }>
              <CloseOutlined  style={{fontSize:"23px"}}/>
          </button>
          <h3 className='kurslar__bar-title'>Barcha kurslar</h3>
          <ul className='kurslar__bar-list'>
            <li><p>Filtrlash</p></li>
            <li
              onClick={()=> {
                setActiv(""); 
                setOpen(!true); 
                setCategory_status("Barchasi"); 
                setPage(1)}} 
              className={activv === ""? `card_maktab${type} k_i_h`: null}
            >Barchasi</li>
            {
              categories?.map((e,i)=>(
                <li 
                  key={i} id={e.id} 
                  onClick={()=> {
                    setActiv(e.id); 
                    setOpen(!true); 
                    setCategory_status(e.name); 
                    setPage(1)}} 
                  className={activv === e.id? `card_maktab${type} k_i_h`: null}
                >
                  {e.name}
                </li>
              ))
            }
          </ul>
        </div>
        <div className="kurslar__main">
          <div className='category__title'>
            <h2>{category_status}</h2>
          </div>
          
          {/* <div className="kurslar__main-konikmalar">
            <p>Tegishli ko'nikmalar</p>
            <button>Kirish</button>
            <button>Asoslari</button>
            <button>2-3 sinflar uchun</button>
            <button>4-5 sinflar uchun</button>
            <button>6-7 sinflar uchun</button>
            <button>8-9 sinflar uchun</button>
            <button>8-9 sinflar uchun</button>
          </div> */}
          <div className="kurslar__main-search">
            <button className='kurslar__filter' onClick={handlerClick }>
              <MenuUnfoldOutlined  style={{fontSize:"23px"}}/>
            </button>
            <select onChange={(e)=>{setActive_status(e.target.value); setPage(1)}} name="" id="status" required>
              <option value="">Barchasi</option>
              {status?.map((item, i)=>{
                return(
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </select>
            <select onChange={(e)=>{setLevel_status(e.target.value); setPage(1)}} name="" id="level" required>
              <option value="">Barchasi</option>
              {levels?.map((item, i)=>{
                return(
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </select>
            <div className='kurslar__main__search'>
              <input type="search" onChange={(e)=>{setSearch_status(e.target.value); setPage(1)}}/>
              <Button onClick={()=>{setSearch_status(search); setPage(1)}}>
                <SearchOutlined style={{fontSize: "27px"}}/>
              </Button>
            </div>
            
          </div>
          <div className="kurslar__card-list">
            { filter_click === false?
              all?.map((item,i)=>{
                return(
                  <Card key={i} item={item}/>
                )
              }) : <RotateCircleLoading color={num} size="large"/>
            }
          </div>
          <div className="kurslar__pagination">
            { 
              filter_click === false
              ? <Pagination 
                  defaultCurrent={page} 
                  pageSize={9} 
                  total={pagination.items_count}
                  onChange={(e)=>{setPage(e)}}
                />
              : ""
            }
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default Kurslar;