import React, { useState } from 'react';
import { Modal } from 'antd';
import { notification } from 'antd';
import { Button } from "@mui/material";
import { RotateCircleLoading } from 'react-loadingg';
// import { SyncOutlined } from '@ant-design/icons';
import A1_bg from "../../../assets/images/Ariza/a1_bg.png";
import A1_pic from "../../../assets/images/Ariza/a1_pic.png";
import A1_bgbot from "../../../assets/images/Ariza/a1_bgbot.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../../constants/base_url';
const ArizaTeacher = () => {
  const openNotification = () => {
    Swal.fire({
      icon: 'success',
      title: 'Tabriklaymiz!',
      text: 'Siznig arizangiz qabul qilindi. Tez orada aloqaga chiqamiz!',
      confirmButtonText: 'Tushunarli'
    })
  };
  const openNotification2 = () => {
    notification.open({
      message: 'Xatolik!',
      description:
        'Ariza qabul qilinmadi.',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [about_course, setAbout_course] = useState("");
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const showModal = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setLoad(true)
    var url = `${BASE_URL}/api/v1/website/teacher-ariza/`
    const data = {
      name: name,
      phone: phone,
      bio: bio,
      about_course: about_course
    }
    axios.post(url, data).then((res) => {
      // console.log(res.data.success)
      if (res.data.success) {
        setTimeout(() => {
          setOpen(false);
          setLoad(false)
          setPhone('')
          setName('')
          setBio('')
          setAbout_course('')
          openNotification()
        }, 1000);
      } else {
        setLoad(false)
        openNotification2()
      }
    })

  };
  const okText = load ? <RotateCircleLoading color="white" /> : "Yuborish"
  return (
    <React.Fragment>
      <div className="ariza__teacher">
        <div className='ariza__tleft'>
          <img src={A1_pic} alt="" />
        </div>
        <div className='ariza__content'>
          <img src={A1_bg} alt="" />
          <h1>Fan o‘qituvchilari uchun</h1>
          <p>Agar siz o‘z metodiga ega, yuqori malakali, o‘z ustida doimiy ishlaydigan o‘qituvchi bo‘lsangiz, va Hokim Akademiyasida dars berishni istasangiz arizangizni qoldiring</p>
          <Button onClick={showModal} variant="contained">Ariza qoldirish</Button>
        </div>
        <Modal
          title="Ariza qoldirish"
          open={open}
          visible={open}
          // closable={true}
          // okText="Saqlash"
          okText={okText}
          onOk={closeModal}
          onCancel={showModal}
          className={`ariza__modal`}

        >
          <div>
            <p>Ism/Familiya</p>
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
          </div>
          <div>
            <p>Telefon</p>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" />
          </div>
          <div>
            <p>O'zi haqida malumot</p>
            <textarea value={bio} onChange={(e) => setBio(e.target.value)} cols="30" rows="10"></textarea>
          </div>
          <div>
            <p>Kursi haqida malumot</p>
            <textarea value={about_course} onChange={(e) => setAbout_course(e.target.value)} cols="30" rows="10"></textarea>
          </div>
        </Modal>
        <img className='ariza__bgbot' src={A1_bgbot} alt="" />
      </div>
    </React.Fragment>
  );
}

export default ArizaTeacher;