import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  CourseInput,
  CourseTextArea,
  EachBox,
  EachBox1,
  InnerContainer,
  LeftContainer,
  MAxContainer,
  ModalContainer,
  RightContainer,
  RightImg,
  SelectContainer,
  SelectTexts,
  Text,
  Title,
} from "./styled";
import Bag from "../../../assets/images/Ariza/arizabag.png";
import Select from "react-select";
import { Modal } from "antd";
import axios from "axios";
import { BASE_URL } from '../../../constants/base_url';
import Swal from "sweetalert2";
const ArizaIkki = () => {
  const [open, setOpen] = useState(false);

  const [languageValue, setlanguageValue] = useState('Barcha Uchun');
  const [Region, setRegion] = useState(null);
  const [school, setschool] = useState('');
  const [district, setdistrict] = useState('');
  const [ariza, setariza] = useState('');
  const [phone, setphone] = useState('');
  const [RegionsOption, setRegionsOption] = useState([]);
  const [CityOption, setCityOption] = useState([]);


  useEffect(() => {
    axios.get(BASE_URL + '/api/v1/website/region/').then((res) => {
      // console.log(res.data.data);
      let newoption = []
      res.data.data?.filter(item => {
        newoption.push({ value: item.id, label: item.name })
      })

      setRegionsOption(newoption)
    })
  }, []);

  useEffect(() => {
    axios.get(BASE_URL + `/api/v1/website/city/?region=${Region}`).then((res) => {
      // console.log("citys",res);
      let newoption = []
      res.data.data?.filter(item => {
        newoption.push({ value: item.id, label: item.name })
      })

      setCityOption(newoption)
    })
  }, [Region, setRegion]);

  const options = [
    { value: 1, label: "Prezident Maktabi uchun" },
    { value: 2, label: "Abituriyent uchun" },
    { value: 3, label: "Chet tillarini o'rganuvchilar uchun" },
    { value: 4, label: "O'qituvchilar uchun" },
    { value: 5, label: "Barcha uchun" },
  ];

  const PostAriza = () => {
    let data = new FormData();
    // console.log(school);
    data.append('name', school)
    data.append('phone', phone)
    data.append('city', district)
    data.append('region', Region)
    data.append('text', ariza)
    try {
      axios.post(BASE_URL + '/api/v1/website/school-ariza/', data).then((res) => {
        if (res.data.success) {
          // console.log(res);
          setOpen(!open);
          setdistrict('')
          setschool('')
          setphone('')
          setariza('')
          Swal.fire({
            icon: 'success',
            title: 'Tabriklaymiz!',
            text: 'Siznig arizangiz qabul qilindi. Tez orada aloqaga chiqamiz!',
            confirmButtonText: 'Tushunarli'
          })
        }
      })
    } catch (err) {

    }
  }


  const showModal = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <Container>
        <MAxContainer>
          <InnerContainer>
            <LeftContainer>
              <Title>Maktablar uchun</Title>
              <Text>
                Ta’lim - bu dunyoni o‘zgartirish uchun ishlatishingiz mumkin bo‘lgan eng kuchli qurol.
                Platformaga maktabingizni ro‘yxatdan o‘tkazish uchun arizangizni qoldiring
              </Text>
              <Button onClick={showModal}>Ariza qoldirish</Button>
            </LeftContainer>
            <RightContainer>
              <RightImg src={Bag} />
            </RightContainer>
          </InnerContainer>
        </MAxContainer>
      </Container>
      <Modal
        title="Ariza qoldirish"
        open={open}
        visible={open}
        // closable={true}
        // okText="Saqlash"
        okText="Yuborish"
        onOk={PostAriza}
        onCancel={showModal}
        className={`ariza_me`}
      >
        <SelectContainer>
          <EachBox>
            <SelectTexts>Viloyat</SelectTexts>
            <Select
              placeholder="Viloyat"
              options={RegionsOption}
              onChange={(e) => {
                setRegion(e.value);
              }}
            />
          </EachBox>
          <EachBox>
            <SelectTexts>Tuman</SelectTexts>
            <CourseInput
              placeholder="Tumaningiz"
              value={district}
              onChange={(e) => {
                setdistrict(e.target.value);
              }}
            />
          </EachBox>
          <EachBox>
            <SelectTexts>Maktab</SelectTexts>
            <CourseInput
              placeholder="Maktabingiz"
              value={school}
              onChange={(e) => {
                setschool(e.target.value);
              }}
            />
          </EachBox>
          <EachBox>
            <SelectTexts>Telefon</SelectTexts>
            <CourseInput
              placeholder="Telefon nomer"
              value={phone}
              onChange={(e) => {
                setphone(e.target.value);
              }}
            />
          </EachBox>
          <EachBox1>
            <SelectTexts>Ariza matni</SelectTexts>
            <CourseTextArea
              placeholder="Ariza matni"
              value={ariza}
              onChange={(e) => {
                setariza(e.target.value);
              }}
            />
          </EachBox1>
        </SelectContainer>
      </Modal>
    </React.Fragment>
  );
};

export default ArizaIkki;
