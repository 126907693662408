import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Korilgan = () => {
  const navigate = useNavigate()
  const [block, setBlock] = useState(-1);

  const ToLessons = (e) => {
    navigate('/kabinet/lesson',{state:e})
  }
  const arr = [1,2,3,4,5,6,7,8,9,10]
  return ( 
    <React.Fragment>
       <div className="barchasi__wrapper">
      <div className="barchasi saqlangan">
        <div className="barchasi_btns">
          <select >
            <option value="barchasi">barchasi</option>
            <option value="matematika">matematika</option>
            <option value="fizika">fizika</option>
            <option value="kimyo">kimyo</option>
          </select>
          <button>Kirish</button>
          <button>3-4 sinflar uchun</button>
          <button>Frontend</button>
          <button>Ingliz tili</button>
        </div>
        <ul className="barchasi__list">
          {
            arr && arr.map((e,i)=>(
              <li key={i}>
            <div className='barchasi__list-inner' onClick={()=>ToLessons(e)}>
              <div className="barchasi__video">
              <img src="https://img.freepik.com/premium-vector/realistic-math-chalkboard-background_23-2148163817.jpg?w=2000" alt="" />
              </div>
              <div className="barchasi__info">
                <div>
                <h3>Matematika7 - sinf</h3>
                <p>Nodir Aliev</p>
                </div>

                  <p className='barchasi__info-mini'>1s 15m <span>.</span> 65 o’quvchi</p>
              </div>
            </div>
            <div className="barchasi__modal">
              <button onClick={()=> block != i ? setBlock(i) : setBlock(-1)} className='button__dot'><i className="bi bi-three-dots"></i></button>
              <div className={block == i? 'barchasi__modal-item barchasi__modal-item-block': 'barchasi__modal-item'}>
              <div>
              <input onClick={()=>  setBlock(-1)} id={`Saqlash${i}`} type="checkbox" />
              <label onClick={()=>  setBlock(-1)} htmlFor={`Saqlash${i}`}>Saqlash</label>
              </div>
              <button onClick={()=>  setBlock(-1)}><i className="bi bi-trash"></i> O'chirish</button>
              </div>
            </div>
          </li>
            ))
          }
        </ul>
      </div>
      </div>
    </React.Fragment>
   );
}
 
export default Korilgan;