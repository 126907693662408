import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';

const Maktab = () => {
  const { loginUser,user } = useContext(AuthContext)
  return ( 
    <React.Fragment>
      {
        user ? <Navigate to='/kabinet' /> : 
        <div className="p__maktab-wrapper">
        <div className="p__maktabi">
          <div className="p__maktabi-inner">
            <h2>Prezident maktabiga kiruvchilar uchun</h2>
            <p>Kirish</p>
            <form className='p__maktabi-form' action="/" onSubmit={(e)=> loginUser(e)}>
                <label htmlFor="user">Username</label>
                <input type="text" name='username' id='user' placeholder='Username'/>
                <label htmlFor="parol">Parol</label>
                <input name='password' type="text" placeholder='Parol'/>
                <button  type='submit'>Kirish</button>
            </form>
          </div>
        </div>
      </div>
      }
    </React.Fragment>
   );
}
 
export default Maktab;