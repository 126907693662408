import styled from 'styled-components';
import { Box } from '@mui/material';


export const Container = styled.div`
    width: 100%;
    background: #1B013C;
`
export const ModalWrapperMain = styled(Box)`
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    /* transform: translate(-50%, -50%) !important; */
    width: 100% !important;
    border: '2px solid #000' !important;
    box-shadow: 24px !important;
    padding: 20px !important;
    height:100vh !important;
    outline:none !important;
    background-color: #000000 !important;
    border:none !important;
    @media (max-width:900px){
        width: 100% !important;
        height: 100% !important;
        padding: 10px !important;
    }
`