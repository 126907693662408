import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
`

export const Wrapper = styled.div`
    max-width:760px;
    width: 98%;

`

export const Blocks = styled.div`
    width: 100%;
    height: 60px;
    background-color: #ebedfc;
    margin-top: 20px;
    border-radius: 5px;
    transition: 0.3s linear;
    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 5px #909090;
    }
`
export const Word = styled.div`
    font-size: 22px;
    font-weight: 40px;
    padding: 10px;
    color: #8f8fff;
`

