let initialState = {
    courseId: null,
    detail: {}
}

const DetailsReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'COURSE_DETAIL':
            let { courseID,details } = action.payload;
            state.courseId = courseID;
            state.detail = details
            return state
        default:
            return state;
    }
}

export default DetailsReducer;