import React from 'react'
import google_play from "../../../assets/images/MobileApp/google_play.png"
import app_store from "../../../assets/images/MobileApp/app_store.png"
import phone from "../../../assets/images/MobileApp/phone.png"

const MobilApp = () => {
  return (
    <div className='mobile__app__container'>
      <div className='mobile__app'>
        {/* <div className="scuare">
          <div></div>
          <div></div>
          <div></div>
        </div> */}
        <div className='app__left'>
          <h1>Akademiyani cho‘ntagingizda olib yuring!</h1>
          <p>Mobil ilovani yuklab oling va qulay foydalaning</p>
          <div>
            <a href="/"><img src={google_play} alt="" /></a>
            <a href="/"><img src={app_store} alt="" /></a>
          </div>
        </div>
        <div className='app__right'>
          <img src={phone} alt="" />
        </div>
      </div>
    </div>
  )
}

export default MobilApp