import React from 'react';
import Home from './Home/Home';
import Waves from './Waves/Waves';
import Gifts from './Gifts/Gifts';
import Footer from "../../View/Footer";
import Navbar from '../../View/Navbar';
// import Reyting from './Reyting/Reyting';
import MobilApp from './MobileApp/MobilApp';
import Chettili from './Oqituvchi/chettili';
import ArizaIkki from './ArizaIkki/ArizaIkki';
import Maktab from './PrezidentMaktab/Maktab';
import HomeVideo from './HomeVideo/HomeVideo';
import Oqituvchi from './Oqituvchi/Oqituvchi';
import ArizaTeacher from './Ariza/ArizaTeacher';
import Abituriyent from './Abituriyent/Abituriyent';

import { BackTop } from 'antd';
import { Container } from './style';
import { UpCircleFilled } from '@ant-design/icons';

const HomeScreen = () => {
  const icon = {
    fontSize: 31,
    color: '#1b0046',
    borderRadius: "50%",
  }
  const style = {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    background: "white",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "1px solid #1b0046",
    zIndex:"100"
  }
  return (
    <Container className='section__container'>
      <Navbar />
      <Home />
      <Waves />
      <Maktab />
      <Abituriyent />
      <Chettili />
      <Oqituvchi />
      {/* <Reyting /> */}
      <Gifts />
      <HomeVideo />
      <ArizaTeacher />
      <ArizaIkki />
      <MobilApp />
      <Footer/>
      <BackTop>

        <div style={style}>
          <UpCircleFilled style={icon}/>
        </div>
      </BackTop>
      
    </Container>
  )
}

export default HomeScreen