import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import { Buts } from "./style";
import Swal from "sweetalert2";
import ChangePassword from "./ChangePassword/ChangePassword";
import { BASE_URL } from '../../../constants/base_url';
const Taxrirlash = () => {
  const [load, setload] = useState(1);
  const [userData, setuserData] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const api = useAxios();
  let formdata = new FormData();
  const imgref = useRef();
  useEffect(() => {
    api.get(`/profile/`).then((res) => {
      setuserData(res.data.data);
    });
  }, []);
  useEffect(() => {
    if (load) {
    }
  }, [load]);

  const ChangePasswordReq = (data) => {
    try {
      api.put("/change-password/", data).then((res) => {
        // console.log(res);
        if (res.data.success) {
          setOpen(false);

          Swal.fire({
            icon: "success",
            title: "Muvoffaqiyat",
            text: "Parolingiz o`zgardi!",
          });
        }
      });
    } catch (err) { }
  };

  const [Rang] = useState(parseInt(window.localStorage.getItem("maktab")));
  const AvatarImg = (e) => {
    imgref.current.src = URL.createObjectURL(e.target.files[0]);
    formdata.append("image", e.target.files[0]);

    api
      .post("/change-image/", formdata)
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => console.log("error", error));
  };
  const [fullname, setfullname] = useState();
  const [username, setusername] = useState();
  const [telegram, settelegram] = useState();
  const [phone, setphone] = useState();
  const [facebook, setfacebook] = useState();
  const [instagram, setinstagram] = useState();
  const [website, setwebsite] = useState();
  const [address, setaddress] = useState();
  useEffect(() => {
    setfullname(userData ? userData.full_name : null);
    setusername(userData ? userData.username : null);
    settelegram(userData ? userData.telegram : null);
    setphone(userData ? userData.phone : null);
    setfacebook(userData ? userData.facebook : null);
    setinstagram(userData ? userData.instagram : null);
    setwebsite(userData ? userData.website : null);
    setaddress(userData ? userData.address : null);
  }, [userData]);
  const handlerPost = (e) => {
    e.preventDefault();
    const formdatas = {
      username: username,
      telegram: telegram,
      phone: phone,
      facebook: facebook,
      instagram: instagram,
      website: website,
      address: address,
    };

    api
      .post("/profile/", formdatas)
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => console.log("error", error));
  };

  const username_loading = useRef();

  const ogohlantrihs = (e) => {
    e.preventDefault();
    alert("Bunday username mavjud");
  };
  const inputValidation = (e) => {
    api
      .get(`/check-username/?username=${e.target.value}`)
      .then((response) => {
        response.data.success == true
          ? (username_loading.current.innerHTML =
            '<i class="bi bi-check-square-fill"></i>')
          : (username_loading.current.innerHTML =
            '<i class="bi bi-x-square-fill"></i>');
        response.data.success == true
          ? (username_loading.current.style.color = "green")
          : (username_loading.current.style.color = "red");
        response.data.success == true ? setload(true) : setload(false);
        // console.log(response.data.success);
      })
      .catch((error) => console.log("error", error));
  };
  // console.log(userData);
  return (
    <div className="taxrirlash">
      <Link
        className={
          Rang === 1
            ? " taxrirlash__btns-bekor1"
            : Rang === 2
              ? " taxrirlash__btns-bekor2"
              : Rang === 3
                ? " taxrirlash__btns-bekor3"
                : Rang === 4
                  ? " taxrirlash__btns-bekor4"
                  : null
        }
        to="/kabinet"
      >
        {" "}
        <i className="bi bi-arrow-left"></i> Kabinetga qaytish
      </Link>
      <div className="taxrirlash__form">
        <h2 className="taxrirlash__form-title">
          Profil malumotlarini tahrirlash
        </h2>
        <div className="taxrirlash__avatar">
          <div
            className={
              Rang === 1
                ? "card_maktab1 taxrirlash__avatar-img"
                : Rang === 2
                  ? "card_maktab2 taxrirlash__avatar-img"
                  : Rang === 3
                    ? "card_maktab3 taxrirlash__avatar-img"
                    : Rang === 4
                      ? "card_maktab4 taxrirlash__avatar-img"
                      : null
            }
          >
            <img
              ref={imgref}
              src={
                userData
                  ? `${BASE_URL}${userData.image}`
                  : null
              }
              alt=""
              required
            />
          </div>
          <div
            className="taxrirlash__avatar-file"
            style={{
              borderColor:
                Rang === 1
                  ? "#D75706 "
                  : Rang === 2
                    ? "#0BA7BF "
                    : Rang === 3
                      ? "#0BA7BF "
                      : Rang === 4
                        ? "#1D794B"
                        : null,
            }}
          >
            <input
              onChange={AvatarImg}
              id="taxrirlash__file"
              type="file"
              required
              placeholder="file yuklang"
            />
            <label
              className={
                Rang === 1
                  ? "card_maktab1 taxrirlash__btns-save "
                  : Rang === 2
                    ? "card_maktab2 taxrirlash__btns-save "
                    : Rang === 3
                      ? "card_maktab3 taxrirlash__btns-save "
                      : Rang === 4
                        ? "card_maktab4 taxrirlash__btns-save "
                        : null
              }
              htmlFor="taxrirlash__file"
            >
              Avatar yuklang
            </label>
          </div>
        </div>

        <form action="#" onSubmit={load ? handlerPost : ogohlantrihs}>
          <div className="taxrirlash__inputs-list">
            <div className="taxrirlash__inputs-inner">
              <div>
                <label htmlFor="ism ">F.I.SH</label>
                <input
                  name="full_name"
                  type="text"
                  id="ism"
                  placeholder="F.I.SH"
                  required
                  value={fullname}
                  onChange={(e) => setfullname(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Tug’ilgan ">Tug’ilgan sana</label>
                <input
                  name="birth_date"
                  type="date"
                  id="Tug’ilgan"
                  placeholder="kun.oy.yil"
                  required
                  value={userData ? userData.birth_date : null}
                />
              </div>
              <div>
                <label htmlFor="O’qishni">O’qishni boshlagan yili</label>
                <input
                  name="start_study_year"
                  type="text"
                  id="O’qishni"
                  placeholder="kun.oy.yil"
                  required
                  value={userData ? userData.start_study_year : null}
                />
              </div>
              <div>
                <label htmlFor="Manzil">Telefon raqam</label>
                <input
                  name="phone"
                  type="tel"
                  id="Telefon"
                  placeholder="+998__ ___ __ __"
                  required
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Manzil">Manzil</label>
                <input
                  name="address"
                  type="Manzil"
                  id="Manzil"
                  placeholder="Tumaningizni tanlang"
                  required
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Yo’nalish">Yo’nalish</label>
                <input
                  name="status"
                  type="Manzil"
                  id="Yo’nalish"
                  placeholder="Prezident maktabi"
                  required
                  value={userData ? userData.status : null}
                />
              </div>
              <div className="username_input">
                <label htmlFor="Username">Username</label>
                <input
                  name="username"
                  type="text"
                  id="Username"
                  placeholder="@username"
                  required
                  value={username}
                  onChange={(e) => {
                    setusername(e.target.value);
                    inputValidation(e);
                  }}
                />
                <span
                  ref={username_loading}
                  className="username_loading"
                ></span>
              </div>
            </div>
            <div className="taxrirlash__inputs-inner">

              <div>
                <label htmlFor="Telegram">Telegram</label>
                <input
                  name="telegram"
                  type="text"
                  id="Tumaningiz"
                  placeholder="Telegram"
                  value={telegram}
                  onChange={(e) => settelegram(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Facebook">Facebook</label>
                <input
                  name="facebook"
                  type="text"
                  id="Facebook"
                  placeholder="link"
                  value={facebook}
                  onChange={(e) => setfacebook(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Instagram">Instagram</label>
                <input
                  name="instagram"
                  type="text"
                  id="Instagram"
                  placeholder="link"
                  value={instagram}
                  onChange={(e) => setinstagram(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Websayt">Websayt</label>
                <input
                  name="website"
                  type="text"
                  id="Websayt"
                  placeholder="link"
                  value={website}
                  onChange={(e) => setwebsite(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="Maktab">Maktab</label>
                <input
                  name="school"
                  type="text"
                  id="Maktab"
                  placeholder="Maktab"
                  value={userData ? userData.school : null}
                />
              </div>

              <div className="taxrirlash__btns">
                <button
                  className={
                    Rang === 1
                      ? " taxrirlash__btns-bekor1"
                      : Rang === 2
                        ? " taxrirlash__btns-bekor2"
                        : Rang === 3
                          ? " taxrirlash__btns-bekor3"
                          : Rang === 4
                            ? " taxrirlash__btns-bekor4"
                            : null
                  }
                >
                  Bekor qilish
                </button>
                <button
                  type="submit"
                  style={{ backgroundColor: load == false ? "gray" : null }}
                  className={
                    Rang === 1
                      ? "card_maktab1 taxrirlash__btns-save"
                      : Rang === 2
                        ? "card_maktab2 taxrirlash__btns-save"
                        : Rang === 3
                          ? "card_maktab3 taxrirlash__btns-save"
                          : Rang === 4
                            ? "card_maktab4 taxrirlash__btns-save"
                            : null
                  }
                >
                  Saqlash
                </button>
              </div>
              <ChangePassword
                open={open}
                close={() => setOpen(false)}
                handleSubmit={ChangePasswordReq}
              />
              <Buts variant="outlined" onClick={handleClickOpen}>
                parolni o'zgartirish
              </Buts>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Taxrirlash;
