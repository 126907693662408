import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import Image from "../../../assets/images/Login/chettili_img.webp"
import AuthContext from '../../../context/AuthContext';

const Chettili = () => {
  const { loginUser,user } = useContext(AuthContext)
  return ( 
    <React.Fragment>
      {
        user ? <Navigate to="/kabinet" /> : 
        <div className="loginpage__container chettili">
        <div className="login__page">
          <div className="login__form">
            <h1 className='login__title'>CHET TILINI O'RGANMOQCHI BOLGANLAR UCHUN</h1>
            <form action="" onSubmit={(e)=>loginUser(e)}>
              <h1>Kirish</h1>
              <p>Username</p>
              <input type="text" name='username' required  placeholder='Username'/>
              <p>Parol</p>
              <input type="password" name='password' required placeholder='Parol'/>
              <button type='submit'>Kirish</button>
            </form>
          </div>
          
          <div className="loginpage__img">
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      }
      
    </React.Fragment>
   );
}
 
export default Chettili;