import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import Video from "./Player";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Container,
  CreateNewPart,
  DetailAc,
  DownContainer,
  InnerDownWrapper,
  InnerMenu,
  LessonContainer,
  LessonMinute,
  LessonText,
  LessonTitle,
  LessonWord,
  LessonWord1,
  Links,
  Links2,
  LinksContainer,
  ListContainer,
  LoadingContainer,
  MenuOpenContainer,
  MenuTitle,
  MylessonsRow,
  MyLessonTitle,
  SaveIcon,
  SaveIcon1,
  TickOutline,
  VideoContainer,
  VideoIcon,
  Wrapper,
} from "./style";
import { Lessons, NavsDATA } from "./data";
import useAxios from "../../../api/useAxios";
import { useDispatch } from "react-redux";
import { BASE_URL } from '../../../constants/base_url';
import Swal from "sweetalert2";
import CustomizedProgressBars from "./Loading/Loading";
import VideoJS from "./VideoJs/VideoJs";
import videojs from "video.js";


const VideoLesson = () => {
  const location = useLocation();
  const { id } = useParams();
  const [show, setshow] = useState(true);
  const [selected_id, setselected_id] = useState(Lessons[0].id);
  const [video_url, setvideo_url] = useState(Lessons[0].link);
  const api = useAxios();
  const dispatch = useDispatch();
  const Base_style =
    localStorage.getItem("maktab") !== null
      ? localStorage.getItem("maktab")
      : "0";
  const [detail, setdetail] = useState({});
  const [loading, setloading] = useState(true);
  const [playing, setplaying] = useState(false);
  const [render, setRender] = useState(false);


  const GetCoursesInDetails = () => {
    try {
      api.get(`/course/detail/${id}/`).then((res) => {
        if (res.data.success) {
          // console.log("alllllllll",res.data.data);
          setdetail(res.data.data);
          if (res.data.data?.sections[0]?.videos[0]?.video_type === "Video") {
            setvideo_url(
              BASE_URL + res.data.data?.sections[0]?.videos[0]?.video
            );
          } else {
            setvideo_url(
              BASE_URL + res.data.data?.sections[0]?.videos[0]?.video_link
            );
          }

          dispatch({
            type: "COURSE_DETAIL",
            payload: { courseID: id, details: res.data.data },
          });
          setloading(false);
        }
      });
    } catch (error) { }
  };

  useEffect(() => {
    GetCoursesInDetails();
  }, [render]);

  const OnSelect = (item, items) => {
    if (detail?.is_saved) {
      if (item.video_type === "Video") {
        setvideo_url(BASE_URL + item.video);
        console.log("videourl", video_url)
      } else {
        setvideo_url(item.video_link);
      }
      // console.log("uri",item);
      setselected_id(items.id);
      setplaying(true);
    } else {
      Swal.fire({
        title: "Eslatma!",
        text: "Qolgan videolarni ko‘rish uchun videoni saqlashingiz kerak",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Saqlash",
        cancelButtonText: "Bekor qilish",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          api.post("/my-course/", { course: detail.id }).then((res) => {
            //  console.log(res);
            if (res.data.success) {
              setRender(!render);
              Swal.fire(
                "Saqlandi!",
                "Ushbu videolar sizning videolistingizga saqlandi",
                "success"
              ).then((res) => {
                window.location.reload();
              });
            }
          });
        }
      });
    }
  };

  const SavedCourse = async (item) => {
    if (!detail.is_saved) {
      let res = await api.post("/my-course/", { course: detail.id });
      if (res.data.success) {
        // console.log(res);
        setRender(!render);
        window.location.reload();
      }
    }
  };

  const playerRef = React.useRef(null);





  return (
    <React.Fragment>
      <Container num={Base_style}>
        <LessonContainer>
          <LessonTitle> {detail?.title}</LessonTitle>
          {loading ? (
            <LoadingContainer>
              <CustomizedProgressBars />
            </LoadingContainer>
          ) : (
            <Wrapper>
              <VideoContainer Base_style={Base_style} num={Base_style}>
                {/* <Video show={playing} setshow={setshow} video_url={video_url} /> */}
                <VideoJS show={playing} setshow={setshow} video_url={video_url} />
              </VideoContainer>
              <ListContainer Base_style={Base_style}>
                {detail?.sections?.length !== 0 ? (
                  detail?.sections?.map((item, index) => (
                    <Accordion
                      key={index}
                      style={{
                        backgroundColor:
                          selected_id == item.id
                            ? Base_style == "1"
                              ? "#FFF6F0"
                              : Base_style == "2"
                                ? "#BAF6FF"
                                : Base_style == "3"
                                  ? "#BAF6FF"
                                  : "#80DDB4"
                            : "",
                      }}
                      className="myaccordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <LessonWord Base_style={Base_style}>
                          {item.title?.slice(0, 20)}
                        </LessonWord>
                      </AccordionSummary>
                      {item?.videos?.map((r, ind) => (
                        <DetailAc
                          key={ind}
                          Base_style={Base_style}
                          onClick={() => OnSelect(r, item)}
                        >
                          <LessonWord1 Base_style={Base_style}>
                            <VideoIcon /> {r?.title?.slice(0, 20)}
                          </LessonWord1>
                          <LessonMinute Base_style={Base_style}>
                            {r?.time}
                            {" minut"}
                          </LessonMinute>
                        </DetailAc>
                      ))}
                    </Accordion>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }}>Darslik mavjud emas</div>
                )}
              </ListContainer>
            </Wrapper>
          )}
        </LessonContainer>
      </Container>
      <DownContainer num={Base_style}>
        <InnerDownWrapper>
          <LinksContainer>
            {NavsDATA?.map((item, ii) => (
              <Links
                key={ii}
                isctive={
                  `/kabinet/lesson/${id}/` + item.path === location.pathname
                    ? 1
                    : 0
                }
                to={item.path}
              >
                {item.name}
              </Links>
            ))}
            <Links2 onClick={() => SavedCourse(detail)}>
              {detail?.is_saved ? "Saqlandi" : "Saqlash"}{" "}
              {detail?.is_saved ? <SaveIcon1 /> : <SaveIcon />}
              <MenuOpenContainer>
                <InnerMenu>
                  <MenuTitle>Saqlash</MenuTitle>
                  <MyLessonTitle>
                    Mening darslarim
                    <TickOutline />
                  </MyLessonTitle>
                  <MylessonsRow>darslar</MylessonsRow>
                  <MylessonsRow>dars 2</MylessonsRow>
                  <CreateNewPart>Yangi bo’lim yaratish</CreateNewPart>
                </InnerMenu>
              </MenuOpenContainer>
            </Links2>
          </LinksContainer>
          <Outlet data={detail} />
        </InnerDownWrapper>
      </DownContainer>

    </React.Fragment>
  );
};

export default VideoLesson;
