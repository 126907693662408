import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";




function CountDown({HandleSubmitTest,QuizTime,StartTimerDown,setStartTimerDown,setHowManyTime}) {


   const REturnTime = (son) => {
        const hours = Math.floor(son / 3600)
        const minutes = Math.floor((son % 3600) / 60)
        const seconds = son % 60
      
        return `${hours}:${minutes}:${seconds}`
  }
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Late</div>;
    }
  
    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{REturnTime(remainingTime)}</div>
        {/* <div className="text">seconds</div> */}
      </div>
    );
  };
  return (
    <div className="App">
    
      <div className="timer-wrapper">
        <CountdownCircleTimer
          key={StartTimerDown}
          isPlaying
          size={60}
          strokeWidth={5}
          trailStrokeWidth={5}
          onUpdate={(r)=>{setHowManyTime(QuizTime-r)}}
          duration={QuizTime}
          colors={['#e9780f', '#f0bc12', '#bda021', '#424d08']}
          colorsTime={[120, 60, 30, 10]}    

          onComplete={() => HandleSubmitTest()}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </div>
  );
}

export default CountDown