
export const Wave = () => (
  <svg className="kabinet__wave" width="1920" height="661" viewBox="0 0 1920 661" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 52.5C0 52.5 276.172 145.532 459.5 149C661.014 152.813 765.183 62.1949 966.5 52.5C1190.13 41.7305 1314.4 142.238 1536.5 114C1691.5 94.2942 1920 0 1920 0V661H0V52.5Z" fill="url(#paint0_radial_1386_3204)"/>
    <defs>
      <radialGradient id="paint0_radial_1386_3204" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(960 236) rotate(90) scale(516.102 999.914)">
        <stop stopColor="#FEBF1B"/>
        <stop offset="1" stopColor="#F07302"/>
      </radialGradient>
    </defs>
  </svg>
)

export const ErrorIcon = () => (
  <svg width="220" height="261" viewBox="0 0 220 261" fill="none">
    <path id='error__path' d="M185.164 17.417H45.8307C36.2254 17.417 28.4141 25.2284 28.4141 34.8337V139.334C28.4141 148.939 36.2254 156.75 45.8307 156.75H71.9557V189.555L126.618 156.75H185.164C194.769 156.75 202.581 148.939 202.581 139.334V34.8337C202.581 25.2284 194.769 17.417 185.164 17.417ZM185.164 139.334H121.794L89.3724 158.779V139.334H45.8307V34.8337H185.164V139.334Z" fill="#FF6666"/>
    <path id='error__path' d="M106.789 52.25H124.206V95.7917H106.789V52.25ZM106.789 104.5H124.206V121.917H106.789V104.5Z" fill="#FF6666"/>
    <g opacity="0.5" filter="url(#filter0_f_1386_9524)">
      <ellipse cx="102.5" cy="226.5" rx="82.5" ry="14.5" fill="url(#paint0_radial_1386_9524)"/>
    </g>
    <defs>
      <filter id="filter0_f_1386_9524" x="0" y="192" width="205" height="69" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1386_9524"/>
      </filter>
      <radialGradient id="paint0_radial_1386_9524" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(102.5 226.5) rotate(90) scale(14.5 82.5)">
        <stop stopColor="#970404"/>
        <stop offset="1" stopColor="#CC0000" stopOpacity="0"/>
      </radialGradient>
    </defs>
  </svg>
)
export const Save = () => (
  <svg width="18" height="23" viewBox="0 0 18 23" fill="none">
    <path d="M0.961197 0.649902C0.961197 0.649902 0.961042 6.0855 0.961042 8.84641C0.961042 11.0751 0.960807 18.4697 0.961042 21.8657C0.961042 21.8657 9.42135 16.2498 9.56991 16.2498C9.71847 16.2498 17.0391 21.9605 17.1661 21.8657C17.2628 14.0245 17.1231 11.0841 17.1231 8.85533C17.1231 6.09441 17.1231 0.649902 17.1231 0.649902H8.89688C8.89688 0.649902 3.23244 0.649902 0.961197 0.649902Z" stroke="white" strokeWidth="1.24674" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const waaa = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#EB7300" fill-opacity="1" d="M0,96L40,117.3C80,139,160,181,240,208C320,235,400,245,480,224C560,203,640,149,720,149.3C800,149,880,203,960,218.7C1040,235,1120,213,1200,181.3C1280,149,1360,107,1400,85.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
  </svg>
)

export const Wave_maktab = () => (
  <svg width="1926" height="706" viewBox="0 0 1926 706" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1361.04 14.4509C1574.1 13.4886 1623.93 123.744 1783.27 78.173C1838.67 62.329 1920.06 37.7432 1920.06 37.7432L1920.06 698.334C1920.06 698.334 1961.38 705 1826.06 705C1690.74 705 1577.45 597.074 1413.55 606.131C1230.24 616.259 1157.18 684.258 974.032 698.334C735.267 716.684 617.394 555.663 379.302 585.09C221.604 604.58 -0.00012207 698.334 -0.00012207 698.334V74.2059C-0.00012207 74.2059 145.58 39.7773 249.009 14.451C453.757 -35.6853 571.4 59.5669 779.526 74.2057C1018.44 91.0101 1121.77 15.5316 1361.04 14.4509Z" fill="#F68004" fill-opacity="0.13"/>
    <path d="M565 33.5C351.955 32.6495 302.122 130.107 142.785 89.8256C87.3872 75.8207 6.00034 54.0887 6.00034 54.0887L6.0006 638C6.0006 638 -35.3123 643.893 100.001 643.893C235.313 643.893 348.603 548.495 512.5 556.5C695.8 565.453 768.858 625.559 952 638C1190.76 654.22 1308.63 511.891 1546.71 537.902C1704.4 555.13 1926 638 1926 638V86.319C1926 86.319 1780.42 55.8867 1677 33.5001C1472.26 -10.8165 1354.62 73.3792 1146.5 86.3188C907.593 101.173 804.264 34.4553 565 33.5Z" fill="url(#paint0_linear_327_3)"/>
    <defs>
      <linearGradient id="paint0_linear_327_3" x1="966" y1="5" x2="966" y2="638" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FCBA13"/>
        <stop offset="1" stop-color="#F47501"/>
      </linearGradient>
    </defs>
  </svg>
)
// export const Note = () => ()
// export const Clock = () => ()
// export const Book = () => ()
// export const Saturn = () => ()
// export const Rocket = () => ()
// export const Lineyka = () => ()
// export const Clipper = () => ()