import React, { useState } from 'react'
import play from "../../../assets/images/HomeVideo/play.png"
import BasicModal from '../../KabinetStudent/VideoLesson/Tavsif/ModalVideo/ModalVideo'
import axios from 'axios'
import { BASE_URL } from '../../../constants/base_url';
import { useEffect } from 'react'
const HomeVideo = () => {
  const [click, setClick] = useState(true)
  const [open, setopen] = useState(false);
  const [videolink, setvideolink] = useState('');

  const handleClick = () => {
    setopen(true)
  }


  const GetVideo = () => {
    try {
      axios.get(BASE_URL + '/api/v1/admin/info/').then((res) => {
        // console.log("res",res);
        if (res.data.success) {
          setvideolink(BASE_URL + res.data.data?.video)
        }
      })
    } catch (err) {

    }
  }

  useEffect(() => {
    GetVideo()
  }, []);



  return (
    <div className='dhomevid'>

      <div className='HomeVideo__container'>
        <BasicModal modalVisible={open} setmodalVisible={setopen} video_url={videolink} />
        <div className="HomeVideo__text" style={click ? { display: "block" } : { display: "none" }}>
          <h1>Kurslar katalogi Sizni kutmoqda! Kirib, tanishib chiqishingiz mumkin.</h1>
          <p>Siz uchun Akademiya bilan taʼlim olish afzalliklarini namoyish etish maqsadida video tayyorladik. Videoni ko‘ring va qabulga qanday tayyorlanishga o‘zingiz qaror qiling.</p>
        </div>
        <button onClick={handleClick} className="play_btn"><img src={play} alt="" /></button>
      </div>
    </div>
  )
}

export default HomeVideo