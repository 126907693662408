import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../constants/base_url';
import g_watch from "../../../assets/images/Gifts/g_watch.png"
import g_handsfree from "../../../assets/images/Gifts/g_handsfree.png"
import g_prise from "../../../assets/images/Gifts/g_prise.png"
import g_tshirt from "../../../assets/images/Gifts/g_tshirt.png"
import ThumbGalery from '../../../components/ThumbGalery';

class Gifts extends Component {
  state = {
    data: []
    // data: [
    //   {id: 1 ,image : G1},
    //   {id: 2 ,image : G2},
    //   {id: 3 ,image : G3},
    //   {id: 4 ,image : G4},
    //   {id: 5 ,image : G5},
    //   {id: 6 ,image : G6},
    //   {id: 7 ,image : G7},
    //   {id: 8 ,image : G8},
    // ]
  }

  componentDidMount() {
    let url = `${BASE_URL}/api/v1/admin/prize/`
    axios.get(url)
      .then((res) => {
        const data = res.data.data
        // console.log(data)
        this.setState({
          data: data
        })
      })
  }



  render() {
    return (
      <React.Fragment>
        <div className="gifts__container">
          <div className="gifts">
            <img src={g_handsfree} className='gifts__images1' alt="" />
            <img src={g_tshirt} className='gifts__images2' alt="" />
            <img src={g_watch} className='gifts__images3' alt="" />
            <img src={g_prise} className='gifts__images4' alt="" />
            <p className="gifts__title">Faol o‘quvchilar uchun bizning sovg‘alar</p>
            <ThumbGalery data={this.state.data} />

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Gifts;