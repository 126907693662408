import React from 'react';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import { Outlet } from 'react-router-dom';

const Kabinet = () => {
  const status = localStorage.getItem("maktab")
  const num = status === "1" ? "1" 
    : status === "2" ? "2" 
    : status === "3" ? "2" 
    : "4"
  
  return ( 
    <React.Fragment>
      <div id={`kabinet${num}`}>
        <Navbar/>
        <Outlet/>
        <Footer/>
      </div>
    </React.Fragment>
   );
}
 
export default Kabinet;