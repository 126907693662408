import React, { Component } from 'react';
import { MyLink, SignInButton, ContainerMain, LogoFooter } from './style';
// import UserMenu from "./UserMenu"
// import Images
// import Logo from "../assets/icons/logo.svg"
import user from "../assets/icons/user.png"
import { Link } from 'react-router-dom';


class Navbar extends Component {
  state = { 
    scroll: 0,
    burger: true,
    thisclass:1,
  }
  // style = {
  //   backgroundColor:'red'
  // }
  
  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  listenToScroll = () => {
    const winScroll =  document.body.scrollTop || document.documentElement.scrollTop

    this.setState({
      scroll: winScroll,
    })
  }
  handleBurger = () => {
    this.setState({
      burger: !this.state.burger
    })
  }
  handleBurgerClose = (son) => {
    // window.scrollTo({
    //   top: son,
    //   behavior: 'smooth'
    // })
    this.setState({
      burger: !this.state.burger
    })
    // this.scrollTop()
  }
  work = (son) => {
    this.setState({
      thisclass:son
    })
  }
  scrollTop = () =>{
    window.scrollTo(0 ,0)
  }
  render() { 
    // console.log("dasdasdasdas",this.props)
    let authData = this.props.auth_data
    let userName = this.props.auth_data !==undefined ?  this.props.auth_data.user.full_name : 'User'
    let userImg = this.props.auth_data !==undefined ? `http://hab.eduapp.uz${this.props.auth_data.user.image}` : user
    return (
      <React.Fragment>
        <ContainerMain>
        {/* <MyLink className={(navData) => (navData.isActive ? 'nav__active' : 'nav__active')} end  to="/">Bosh sahifa</MyLink>
        <MyLink className={(navData) => (navData.isActive ? 'nav__active' : null)}  end to="/courses">Kurslar</MyLink>
        <MyLink className={(navData) => (navData.isActive ? 'nav__active' : null)} end to="/aboutus">Biz Haqimizda</MyLink>
        <MyLink className={(navData) => (navData.isActive ? 'nav__active' : null)} end to="/contact">Kontakt</MyLink> */}
        <div onClick={()=>this.handleBurgerClose(0)} className={this.state.burger ? "nav__open__notshow" : "nav__open__show" }></div>
        <div className={this.state.scroll < 30 ? "navbar" :"navbar nav__scrolled" }>
          <div className="nav__fixed">
            <div className="logo">
              <a href='/' onClick={this.Navigation}>
                <LogoFooter/>
                {/* <img style={{width:100}}  onClick={this.Navigation} src={Logo} alt="" /> */}
                <p><span>Hokim</span> Akademiyasi</p>
              </a>
            </div>
            <div className={this.state.burger ? "nav" : "nav nav__open" }>
              <nav>
                <MyLink onClick={()=>this.handleBurgerClose()} href="#maktab">Prezident maktabi</MyLink>
                <MyLink onClick={()=>this.handleBurgerClose()} href="#abiturient">Abituriyentlar</MyLink>
                <MyLink onClick={()=>this.handleBurgerClose()} href="#chettili">Chet tillari</MyLink>
                <MyLink onClick={()=>this.handleBurgerClose()} href="#oqituvchi">O‘qituvchilar</MyLink>
              </nav>
              {authData === undefined
                ? <div>
                    <SignInButton onClick={()=>this.work(5)} style={{boxShadow:this.state.thisclass === 5 ? '0px 0px 10px 1px #02B8EB' : ''}}   to="/login">Kirish</SignInButton>
                    {/* <SignUpButton onClick={()=>this.work(6)} style={{boxShadow:this.state.thisclass === 6 ? '0px 0px 10px 1px #02B8EB' : ''}}   to="/register">Ro'yhatdan o'tish</SignUpButton> */}
                  </div>
                : 
                <div className="user__menu">
                    <Link to='/dashboard/allcourses'>
                       {userName}
                      <img src={ this.props.auth_data.user.image ===null ? user :  userImg} alt="" />
                    </Link>
                  </div>
              }
              
            </div>
            <div className='burger__btn'>
              <button onClick={this.handleBurger} className={this.state.burger ? 'burger__open' : "burger__open burger__close" }>
                <span></span>
                <span></span>
                <span></span>
              </button> 
            </div>
          </div>
        </div>
        </ContainerMain>
      </React.Fragment>
    );
  }
}
 
export default React.memo(Navbar);

