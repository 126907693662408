import React, { useContext, useState } from 'react';
import Search from './Search';
import { Dropdown, Menu } from "antd";
import { useLocation, Link } from "react-router-dom"

import Logo from "../../../assets/icons/logo_kabinet.svg"
// import User from "../../../assets/images/Kabinet/user.png"
import AuthContext from '../../../context/AuthContext';
import { Button } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import useAxios from "../../../api/useAxios"
import { useEffect } from 'react';
import { BASE_URL } from '../../../constants/base_url';



const Navbar = () => {
  const api = useAxios()
  let user = JSON.parse(localStorage.getItem('user'))
  const [searchBtn, setSearchBtn] = useState(true)
  const { logOutUser } = useContext(AuthContext)
  const handleSearchBtn = () => {
    setSearchBtn(!searchBtn)
  }




  const status = localStorage.getItem("maktab")
  const location = useLocation().pathname.slice(0, 9)
  const num = status === "1" ? "1"
    : status === "2" ? "2"
      : status === "3" ? "2"
        : "4"
  const menu = (
    <Menu
      className='k__mobile'
      items={[
        {
          label: <Link to="/kabinet/kurslar">Kurslar</Link>,
          className: "a1",
          key: "0",
        },
        {
          label: <Link to="/kabinet/saved">Mening kurslarim</Link>,
          className: "a1",
          key: "1"
        },
        {
          label: <Link to="/kabinet/taxrirlash">Tahrirlash</Link>,
          key: "2"
        },
        {
          label: <Link to="#" onClick={logOutUser}>Chiqish</Link>,
          key: "3"
        },
      ]}
    />
  );
  const [data, setData] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const SearchUrl = `/search-course/?search=${searchWord}`
  const HandleSearch = () => {
    api.get(`${SearchUrl}`)
      .then((r) => {
        setData(r.data.data)
      })
  }
  const [search, setSearch] = useState(true);
  useEffect(() => {
    if (searchWord !== "") {
      setTimeout(() => {
        HandleSearch()
        setSearch(false)
      }, 500);
    } else {
      setData([])
      setSearch(true)
    }
  }, [searchWord])


  const Last_Login = async () => {
    try {
      const res = await api.get('/last-login/');
      console.log("last login", res.data);

      if (res.data.success) {
        console.log("last login", res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    Last_Login()
  }, []);



  return (
    <React.Fragment>
      <div className='kabinet__navbar__container' id={location === "/kabinet/" ? `kabinet__nav${num}` : `kabinet__navbar${num}`}>
        <div className="kabinet__navbar">
          <div className="kabinet__logo">
            <Link to="/kabinet" onClick={() => { setSearchWord("") }}>
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className="kabinet__nav">
            <Link to="/kabinet/kurslar" onClick={() => { setSearchWord("") }}>Kurslar</Link>
            <div className="kabinet__search">
              <Search data={data} setSearchWord={setSearchWord} />
              <div>
                <input type="search" value={searchWord} onChange={(e) => { setSearchWord(e.target.value) }} placeholder='Qidiruv...' />
              </div>
              <span onClick={handleSearchBtn}>
                <svg width="26" height="25" viewBox="0 0 26 25" fill="none">
                  <path d="M20.5615 18.7658L23.6327 21.8158C23.7759 21.9631 23.8551 22.1604 23.8534 22.3653C23.8516 22.5701 23.7689 22.7661 23.6231 22.9109C23.4773 23.0558 23.2801 23.1379 23.074 23.1397C22.8678 23.1415 22.6692 23.0628 22.5209 22.9204L19.4498 19.8689C17.4429 21.5774 14.848 22.4471 12.2096 22.2956C9.57126 22.144 7.09451 20.983 5.2991 19.0561C3.50368 17.1292 2.52916 14.5862 2.58002 11.9608C2.63088 9.33534 3.70317 6.83151 5.57189 4.9747C7.44061 3.11788 9.96049 2.05242 12.6028 2.00188C15.245 1.95135 17.8043 2.91966 19.7436 4.70364C21.6828 6.48762 22.8513 8.94859 23.0038 11.5702C23.1563 14.1917 22.281 16.7701 20.5615 18.7642V18.7658ZM12.7996 20.7501C15.0934 20.7501 17.2933 19.8447 18.9153 18.2331C20.5372 16.6214 21.4484 14.4356 21.4484 12.1564C21.4484 9.87717 20.5372 7.69132 18.9153 6.07968C17.2933 4.46804 15.0934 3.56263 12.7996 3.56263C10.5058 3.56263 8.30593 4.46804 6.68395 6.07968C5.06198 7.69132 4.15077 9.87717 4.15077 12.1564C4.15077 14.4356 5.06198 16.6214 6.68395 18.2331C8.30593 19.8447 10.5058 20.7501 12.7996 20.7501Z" fill="white" />
                </svg>
              </span>
            </div>
            <Link to="/kabinet/saved" onClick={() => { setSearchWord("") }}>Mening kurslarim</Link>
            <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
              <button onClick={(e) => { e.preventDefault(); setSearchWord("") }}>
                <img src={BASE_URL + user.image} alt="" />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div onClick={() => { setSearchWord("") }} className={search ? "" : "search__working"}></div>
      <div className={searchBtn ? "search__result" : "search__result kabinet__search__show"}>
        <div className='search__input'>
          <input type="search" value={searchWord} onChange={(e) => { setSearchWord(e.target.value) }} placeholder='Qidiruv...' />
          <Button onClick={() => { handleSearchBtn(); setSearchWord("") }}>
            <CloseOutlined style={{ fontSize: "23px", color: "black" }} />
          </Button>
        </div>
        <Search data={data} setSearchWord={setSearchWord} />
      </div>
    </React.Fragment>
  );
}

export default Navbar;