import React, { Component } from 'react';
import { 
  SemipolarLoading,
  BabelLoading,
  BlockLoading,
  BlockReserveLoading,
  BoxLoading,
  CircleLoading,
  CircleToBlockLoading,
  CommonLoading,
  DisappearedLoading,
  LoopCircleLoading,
  NineCellLoading,
  TouchBallLoading,
  TransverseLoading,
  WaveLoading,
  WaveTopBottomLoading,
  WindMillLoading,
  JumpCircleLoading,
  MeteorRainLoading,
  RotateCircleLoading,
  StickyBallLoading,
  SolarSystemLoading,
  LadderLoading,
  HeartBoomLoading,
  RollBoxLoading,
  RectGraduallyShowLoading,
  PointSpreadLoading,
  ThreeHorseLoading,
  PassThrouthLoading,
  CoffeeLoading,
  BatteryLoading,
  DiamonLoading	,
  EatLoading
} from 'react-loadingg';
import "./app.css"

class Loading extends Component {
  state = {  } 
  render() { 
    return (
      <React.Fragment>
        <div className='loadings'>
          <div className='load1'>< SemipolarLoading  />SemipolarLoading</div>
          <div className='load1'>< BabelLoading />BabelLoading</div>
          <div className='load1'>< BlockLoading />BlockLoading</div>
          <div className='load1'>< BlockReserveLoading />BlockReserveLoading</div>
          <div className='load1'>< BoxLoading />BoxLoading</div>
          <div className='load1'>< CircleLoading />CircleLoading</div>
          <div className='load1'>< CircleToBlockLoading />CircleToBlockLoading</div>
          <div className='load1'>< CommonLoading />CommonLoading</div>
          <div className='load1'>< DisappearedLoading />DisappearedLoading</div>
          <div className='load1'>< LoopCircleLoading />LoopCircleLoading</div>
          <div className='load1'>< NineCellLoading />NineCellLoading</div>
          <div className='load1'>< TouchBallLoading />TouchBallLoading</div>
          <div className='load1'>< TransverseLoading />TransverseLoading</div>
          <div className='load1'>< WaveLoading />WaveLoading</div>
          <div className='load1'>< WaveTopBottomLoading />WaveTopBottomLoading</div>
          <div className='load1'>< WindMillLoading />WindMillLoading</div>
          <div className='load1'>< JumpCircleLoading />JumpCircleLoading</div>
          <div className='load1'>< MeteorRainLoading />MeteorRainLoading</div>
          <div className='load1'>< RotateCircleLoading />RotateCircleLoading</div>
          <div className='load1'>< StickyBallLoading />StickyBallLoading</div>
          <div className='load1'>< SolarSystemLoading />SolarSystemLoading</div>
          <div className='load1'>< LadderLoading />LadderLoading</div>
          <div className='load1'>< HeartBoomLoading />HeartBoomLoading</div>
          <div className='load1'>< RollBoxLoading />RollBoxLoading</div>
          <div className='load1'>< RectGraduallyShowLoading />RectGraduallyShowLoading</div>
          <div className='load1'>< PointSpreadLoading />PointSpreadLoading</div>
          <div className='load1'>< ThreeHorseLoading />ThreeHorseLoading</div>
          <div className='load1'>< PassThrouthLoading />PassThrouthLoading</div>
          <div className='load1'>< CoffeeLoading />CoffeeLoading</div>
          <div className='load1'>< BatteryLoading />BatteryLoading</div>
          <div className='load1'>< DiamonLoading />DiamonLoading</div>
          <div className='load1'>< EatLoading />EatLoading</div>
        </div>
        <div className="npm__link">
          <a href="https://www.npmjs.com/package/react-loadingg" target="_blank"> {">>>"} NPM.js {"<<<"} </a>
        </div>
        
      </React.Fragment>
    );
  }
}
 
export default Loading;