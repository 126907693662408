import React, { useEffect, useState } from 'react';
import Snow from './Detallar/Snow';
import Atropos from 'atropos/react';
import { Wave } from './Detallar/icons';
import { Link } from "react-router-dom";
import useAxios from "../../../api/useAxios";

import Book from "./Detallar/img/book.svg";
import Note from "./Detallar/img/note.svg";
import Clock from "./Detallar/img/clock.svg";
import Paper from "./Detallar/img/paper.png";
import Saturn from "./Detallar/img/saturn.svg";
import Rocket from "./Detallar/img/rocket.svg";
import Lineyka from "./Detallar/img/lineyka.svg";
import Clipper from "./Detallar/img/clipper.svg";
import Shapka1 from "./Detallar/img/shapka1.svg";
import Logo from "../../../assets/images/Kabinet/logo.png";
import Abiturient from "../../../assets/images/Kabinet/paper.webp";
import Oqituvchi from "../../../assets/images/Kabinet/oqituvchi_headerbot.png";
import { BASE_URL } from '../../../constants/base_url';

const Header = () => {
  const api = useAxios()
  const status = localStorage.getItem("maktab")
  const num = status === "1" ? "1"
    : status === "2" ? "2"
      : status === "3" ? "2"
        : "4"
  const scrollTop = () => {
    window.scrollTo(0, 0)
  }
  const [header_data, setHeader_data] = useState([])
  useEffect(() => {
    api.get(`/course-home/`)
      .then((res) => {
        // console.log(res.data.data)
        setHeader_data(res.data.data.three_course)
      })
  }, [])
  function returnword(word, len) {

    if (word?.length < len) {
      return word?.slice(0, len)
    } else {
      return `${word?.slice(0, len)}...`
    }
  }
  return (
    <React.Fragment>
      <div className="kabinet__header" id={`kabinet__header${num}`}>
        <h1>{status == '1' ? "O‘qi, ta’lim ol. Sen bilan bugun ota-onang, ertaga esa Vataning faxrlansin!" : status == '4' ? 'O‘qituvchilik–boshqa barcha kasblarni yaratadigan yagona kasbdir.' : status == '3' ? "Hayot—bu chet tili, uni o‘rganmay turib tushinib bo‘lmaydi" : 'O‘zga tilni o‘rganish - yangi dunyoda yaxshiroq yashash demak'}</h1>
        <p>{'O‘zingizga qiziq deb bilgan ihtiyoriy uchta darsdan birini ko‘ring va bizning Akademiyamizni tanlab adashmaganingizga ishonch hosil qiling! '}</p>
        <div className="header__items">
          {header_data?.map((item, index) => {
            return (
              <Atropos
                shadow={false}
                className="kabinet__atropos"
                rotateYMax={5}
                key={index}
              >
                <div className="header__item">
                  <div className="header__item__img">
                    <img src={`${BASE_URL}/${item.image}`} alt="" />
                  </div>
                  <b>{returnword(item.title, 20)}</b>
                  <span>
                    {returnword(item.short_description, 200)}
                  </span>
                  <Link onClick={scrollTop} to={`/kabinet/lesson/${item.id}`}>Boshlash</Link>
                </div>
              </Atropos>
            )
          })}
        </div>
        {num === "1"
          ? <div className='maktab__items'>
            <div className='maktab__item'>
              <img src={Book} className="m_i1" style={{ "--i": "0" }} alt="" />
              <img src={Clock} className="m_i2" style={{ "--i": "1" }} alt="" />
              <img src={Note} className="m_i3" style={{ "--i": "2" }} alt="" />
            </div>
            <div className='maktab__item'>
              <img src={Saturn} className="m_i4" style={{ "--i": "3" }} alt="" />
              <div>
                <img src={Rocket} className="m_i5" style={{ "--i": "4" }} alt="" />
                <img src={Clipper} className="m_i6" style={{ "--i": "5" }} alt="" />
              </div>
              <img src={Lineyka} className="m_i7" style={{ "--i": "6" }} alt="" />
            </div>
          </div>
          : num === "2"
            ? <div className='maktab__items abiturient__items'>
              <div className='maktab__item'>
                <img src={Paper} className="m_i7" style={{ "--i": "5" }} alt="" />
              </div>
              <div className='maktab__item'>
                <img src={Shapka1} className="m_i5" style={{ "--i": "5" }} alt="" />
              </div>
            </div>
            : ""}
        {num === "1"
          ? <div className="show__maktab">
            <Snow />
            <Wave />

          </div>
          : num === "2"
            ? <div className='show__abiturient'>
              <img src={Abiturient} alt="" />
              <img src={Logo} alt="" />
            </div>
            : num === "3"
              ? <div className='show__chettili'></div>
              : <div className='show__oqituvchi'>
                <img src={Oqituvchi} alt="" />
              </div>
        }
      </div>
    </React.Fragment>
  );
}

export default Header;