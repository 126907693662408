import React, {useState} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle";

import { FreeMode, Navigation, Thumbs } from "swiper";
import {BASE_URL} from '../constants/base_url';

const ThumbGalery = (props) => {
  const data = props.data
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return ( 
    <React.Fragment>
      <Swiper
        slidesPerView={1}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={50}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        breakpoints={{
          10: {
            spaceBetween: 0
          },
          320: {
            spaceBetween: 0
          },
          650: {
            spaceBetween: 30
          },
          768: {
            spaceBetween: 30
          },
          1024: {
            spaceBetween: 40
          },
          1440: {
            spaceBetween: 50
          },
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {data?.map((item, index)=>{
          return(
            <SwiperSlide className='gift__swiper' key={index}>
              <img src={BASE_URL+item.image} alt=""/>
              {/* <img src={item.image} /> */}
            </SwiperSlide>
          )
        })}
        
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        slidesPerView={7}
        freeMode={true}
        watchSlidesProgress={true}
        breakpoints={{
          10: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 3,
          },
          375: {
            slidesPerView: 3,
          },
          650: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 5,
          },
          1440: {
            slidesPerView: 6,
          },
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {data?.map((item, index)=>{
          return(
            <SwiperSlide className='gift__swiper' key={index}>
              <img src={BASE_URL+item.image} alt=""/>
              {/* <img src={item.image} /> */}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </React.Fragment>
   );
}
 
export default ThumbGalery;