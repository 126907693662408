import styled from "styled-components";

export const Buts = styled.div`
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #6b6bff;
    text-align: center;
    padding-top:20px;
    &:hover{
        cursor: pointer;
    }
    &:active{
        color:#8e8efa ;
    }
`