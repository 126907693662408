import React from 'react';
import dateFormat from "dateformat";
import { Save } from "./Detallar/icons";
import { Link } from "react-router-dom";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BASE_URL } from '../../../constants/base_url';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const Slider = (props) => {
  var data = props.data
  // console.log(data)
  const status = localStorage.getItem("maktab")
  const num = status === "1" ? "1"
    : status === "2" ? "2"
      : status === "3" ? "2"
        : "4"
  const scrollTop = () => {
    window.scrollTo(0, 0)
  }
  function returnword(word, len) {

    if (word?.length < len) {
      return word?.slice(0, len)
    } else {
      return `${word?.slice(0, len)}...`
    }
  }
  return (
    <React.Fragment>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={true}
        breakpoints={{
          10: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          650: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1440: {
            slidesPerView: 4,
          },
        }}
        modules={[Pagination, Navigation]}
        className="kabinet__swiper"
        id={`kabinet__swiper${num}`}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="swiper__box">
                <div className="swiper__img">
                  <img src={BASE_URL + item.image} alt="" />
                  {item.status === "Top" ? <span className='swiper__top'>TOP</span> : ""}
                  <span className='swiper__soni'>{item.lessons_count} dars</span>
                </div>
                <div className="swiper__user">
                  <p>{returnword(item.teacher?.full_name, 20)}</p>
                  <div>
                    <img src={BASE_URL + item.teacher?.image} alt="" />
                    {/* <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" alt="" /> */}
                  </div>
                </div>
                <div className="swiper__info">
                  <div>
                    <p>{returnword(item.title, 20)}</p>
                    <span>
                      {/* {item.views} ko'rildi •  */}
                      {dateFormat(item.date_added, "dd-mm-yyyy")}
                    </span>
                  </div>
                  <div>
                    {/* <button>
                    </button> */}
                    <Save />
                  </div>
                </div>
                <Link onClick={scrollTop} to={`/kabinet/lesson/${item.id}/`} className='swiper__btn'>Boshlash</Link>
              </div>
            </SwiperSlide>
          )
        })}

      </Swiper>
    </React.Fragment>
  );
}

export default Slider;