import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import Image from "../../../assets/images/Login/oqituvchi__img.webp"
import AuthContext from '../../../context/AuthContext';

const Oqituvchi = () => {
  const { loginUser,user } = useContext(AuthContext)
  return ( 
    <React.Fragment>
      {
        user ? <Navigate to='/kabinet' /> : 
        <div className="loginpage__container oqituvchi">
        <div className="login__page">
          <div className="login__form">
            <form action="" onSubmit={(e)=>loginUser(e)}>
              <h2>O'QITUVCHILAR UCHUN</h2>
              <h1>Kirish</h1>
              <p>Username</p>
              <input type="text" name='username' required placeholder='Username'/>
              <p>Parol</p>
              <input type="password" name='password' required placeholder='Parol'/>
              <button type='submit'>Kirish</button>
            </form>
          </div>
          <div className="loginpage__img">
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      }
      
    </React.Fragment>
   );
}
 
export default Oqituvchi;