import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import FallbackLoading from './FallbackLoading';
import {BrowserRouter as Router} from "react-router-dom"
import store from './store';
import App from './App';
import {Provider} from 'react-redux';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Suspense fallback={FallbackLoading}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>
);
