import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../constants/base_url';

function Card(item) {
      // console.log(item.item)
      function returnword(word, len) {

            if (word?.length < len) {
                  return word?.slice(0, len)
            } else {
                  return `${word?.slice(0, len)}...`
            }
      }
      var i = item.item
      const navigate = useNavigate()
      const ToLessons = (name) => {
            navigate(`/kabinet/lesson/${item.item.id}/`, { state: item.item })
      }
      const [Rang, setRang] = useState(window.localStorage.getItem('maktab'));
      return (
            <div className="card">
                  {i.status === "Top" ? <span className='card__top'>Top</span> : ""}
                  <img className='card__img' src={`${BASE_URL + "/" + i.image}`} alt="" />
                  <div className="card__inner">
                        <div className="card__avatar">
                              <p>{returnword(i.teacher?.full_name, 20)}</p>
                              <img src={`${BASE_URL + "/" + i.teacher?.image}`} alt="" />
                        </div>
                        <div className="card__name">
                              <p>{returnword(i.title, 30)}</p>
                              <button className='card__name-btn'><i className="bi bi-bookmark"></i></button>
                        </div>
                        <span>{i.date_added}</span>
                        <button onClick={() => ToLessons()} className={Rang == 1 ? 'card_maktab1' : Rang == 2 ? 'card_maktab2' : Rang == 3 ? 'card_maktab3' : Rang == 4 ? 'card_maktab4' : null} >Boshlash</button>
                  </div>
            </div>
      )
}

export default Card
