import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../api/useAxios';
import { BASE_URL } from '../../../constants/base_url';
const Barchasi = () => {
  const api = useAxios()
  const [block, setBlock] = useState(0);
  const [Courses, setCourses] = useState();
  const [Status, setStatus] = useState();
  const [Categories, setCategories] = useState();
  const navigate = useNavigate()

  const get_courses = () => {
    api.get(`/my-course/`)
      .then((res) => {
        if (res.data.success) {
          setCourses(res.data.data.result);
        }
      })
  }
  useEffect(() => {
    get_courses()
  }, [block])
  useEffect(() => {
    api.get(`/course-status/`)
      .then((res) => {
        setStatus(res.data.data);
      })
  }, [])
  useEffect(() => {
    api.get(`/category/`)
      .then((res) => {
        setCategories(res.data.data);
      })
  }, [])
  const ToLessons = (e) => {
    navigate(`/kabinet/lesson/${e.course.id}/`, { state: e })
  }
  const searchCourse = (e) => {
    api.get(`/courses/?search=tan${e.target.value}`)
      .then((res) => {
        setCourses(res.data.data.result);
      })
  }
  const searchCategory = (e) => {
    let id = e.target.value
    api.get(`/courses/?search=category${id}`)
      .then((res) => {
        setCourses(res.data.data.result);
        // console.log(e.target.value);
      })
  }
  const searchStatus = (e) => {
    api.get(`/courses/?search=status${e.target.value}`)
      .then((res) => {
        setCourses(res.data.data.result);
      })
  }
  // console.log(Courses);
  const handlerDelete = (e) => {
    api.delete(`/my-course/${e}/`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          get_courses()
        }
      })
  }


  return (
    <React.Fragment>
      <div className="barchasi__wrapper">
        <div className="barchasi saqlangan">
          <div className="barchasi_btns">
            <input onChange={searchCourse} type="text" className='barchasi_btns-input' placeholder='Qidiruv' />
            <select onChange={searchCategory}>
              <option value="">Kategoriya</option>
              {
                Categories && Categories.map((e, i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                ))
              }
            </select>
            <select onChange={searchStatus}>
              <option value="">Holat</option>
              {
                Status && Status.map((e, i) => (
                  <option key={i} value={e.id}>{e.name}</option>
                ))
              }
            </select>

          </div>
          <ul className="barchasi__list">
            {
              Courses && Courses.map((e, i) => (
                <li id={e.id} style={{ display: block == e.id ? 'none' : '' }} key=
                  {i}>
                  <div className='barchasi__list-inner ' onClick={() => ToLessons(e)}>
                    <div className="barchasi__video">
                      <img src={`${BASE_URL}${e.course ? e.course.image : e.image}`} alt="" />
                    </div>
                    <div className="barchasi__info">
                      <div>
                        <h3>{e.course ? e.course.title : e.title}</h3>
                        <p>{e.course ? e.course.teacher.full_name : e.teacher.full_name}</p>
                      </div>

                      <p className='barchasi__info-mini'>{e.course ? e.course.total_time : e.total_time} <span></span> 65 o’quvchi</p>
                    </div>
                  </div>
                  <div className="barchasi__modal">
                    <button onClick={() => { handlerDelete(e.id); setBlock(e.id); }} className='button__dot'><i className="bi bi-trash"></i></button>

                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Barchasi;