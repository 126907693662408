import React, { useEffect, useState } from 'react';
import { Container, DegreeImg, DegreeShow, EachBox, EachText, Fullname, ImgBlock, ImgInfoWrapper, InfoTextContainer, InnerImg, JobName, LabelStars, LastDefinition, LessonTitle, Links, LinksContainer, LitText, LitText1, MyAvatar, ProfileFirstLine, ProfileInfoWrapper, ProfileSecondLine, RightBlock, StarIcon, TavsifContianer, TextInfoWrapper, TickIcon } from './style';
import { Lorems } from '../data';
import InfoBG from '../../../../assets/images/lesson/infobg.png'
import Degree from '../../../../assets/images/lesson/degree.png'
import Me from '../../../../assets/images/lesson/me1.jpg';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../constants/base_url';
import { LoadingContainerTest } from '../style';
import BasicModal from './ModalVideo/ModalVideo';



const Tavsif = () => {
   let details = useSelector(state => state.detail);
   const [modalVisible, setmodalVisible] = useState(false);
   const [video_url, setvideo_url] = useState(null);
   // console.log("det",details);

   const { detail } = details;
   const [render, setrender] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setrender(!render)
      }, 500);
   }, []);

   const OpenVideoModal = (item) => {
      setmodalVisible(true)
      setvideo_url(BASE_URL + item.video)
   }


   return (
      <React.Fragment>
         {detail === null ?
            <LoadingContainerTest>
               Loading...
            </LoadingContainerTest>
            :
            <Container>
               <BasicModal video_url={video_url} modalVisible={modalVisible} setmodalVisible={setmodalVisible} />
               <LessonTitle>{detail?.title}</LessonTitle>
               <TavsifContianer>
                  <ImgInfoWrapper>
                     <ImgBlock>
                        <InnerImg src={detail?.image === null ? InfoBG : BASE_URL + detail?.image} />
                        <InfoTextContainer>
                           <LitText>{detail?.lessons_count} blog</LitText>
                           <LitText>{detail?.tests} Topshiriq</LitText>
                           <LitText>{detail?.total_time}</LitText>
                           <LitText1><TickIcon /> 30%</LitText1>
                        </InfoTextContainer>
                     </ImgBlock>
                  </ImgInfoWrapper>
                  <TextInfoWrapper>
                     {detail?.short_description}
                  </TextInfoWrapper>
                  <ProfileInfoWrapper>
                     <ProfileFirstLine>
                        <EachBox>
                           <DegreeImg src={Degree} />
                           <EachText>O'rta daraja</EachText>
                        </EachBox>
                        <EachBox>
                           <DegreeShow>{detail?.students}</DegreeShow>
                           <EachText>O'quvchilar</EachText>
                        </EachBox>
                        <EachBox>
                           <DegreeShow>{detail?.tests}</DegreeShow>
                           <EachText>Testlar</EachText>
                        </EachBox>
                     </ProfileFirstLine>
                     <ProfileSecondLine>
                        <MyAvatar src={BASE_URL + detail?.teacher?.image} />
                        <RightBlock>
                           <Fullname>{detail?.teacher?.full_name}</Fullname>
                           <JobName>{detail?.teacher?.title}</JobName>
                           <LabelStars onClick={() => OpenVideoModal(detail?.teacher)}><StarIcon /> Ma'lumot</LabelStars>
                        </RightBlock>
                     </ProfileSecondLine>
                  </ProfileInfoWrapper>
               </TavsifContianer>
               <LastDefinition>
                  <p dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
               </LastDefinition>
            </Container>}
         <span className="ai-download"></span>
      </React.Fragment>
   );
}

export default Tavsif;