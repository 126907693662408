import { IconButton } from '@mui/material';
import styled from 'styled-components';
import {  Dropdown } from 'antd';

export const Container = styled.div`
    width:100%;
    height: 100%;
    position: relative !important;
`

export const VideoWrapper = styled.video`
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 9 !important;
    .vjs-big-play-button{
        top: calc(50% - 1.6em/2);
        left: calc(50% - 3em/2);
    }
`

export const IconBtn = styled(IconButton)`
    /* position: absolute !important;
    z-index: 10 !important;
    right: 10px !important;
    top: 5px !important; */
    background-color: rgba(1,1,1,0.4) !important;
    color: #ffffff !important;
`
export const Dropdownme = styled(Dropdown)`
    position: absolute !important;
    z-index: 10 !important;
    right: 10px !important;
    top: 5px !important;
    color: #c4c4c4 !important;
`