import React from 'react';
import 'atropos/css'
import {Container,PupilBox,TextDiv,Title,BR,Batafsil,Wrapper,BagImg, BookImg, NoteBookImg, ClockImg, SaturnImg, ChizgichImg, RoketImg, LittleTitle} from './style'
import Bag from '../../../assets/images/bag.png';
import Book from '../../../assets/images/book.png';
import NoteBook from '../../../assets/images/notebook.png';
import Clock from '../../../assets/images/clock.png';
import Saturn from '../../../assets/images/saturn.png';
import Chizgich from '../../../assets/images/chiz.png';
import Rocket from '../../../assets/images/rocket.png';


const Maktab = () => {
  
  return (
    <Container>
      <Wrapper>
      <PupilBox>
        <TextDiv>
           <Title>
              Prezident maktabiga <BR />kiruvchilar uchun
           </Title>
           <LittleTitle>Har bir kun - o‘rganish kuni!</LittleTitle>
           <Batafsil href='/login/maktab'>Boshlash</Batafsil>
        </TextDiv>
      </PupilBox>
        <BagImg className='maktab_qurollari1'  src={Bag} />
        <BookImg  src={Book}/>
        <NoteBookImg src={NoteBook} />
        <ClockImg className='maktab_qurollari2' src={Clock}  id="maktab"/>
        <SaturnImg className='maktab_qurollari2' src={Saturn} />
        <ChizgichImg  src={Chizgich} />
        <RoketImg className='maktab_qurollari1' src={Rocket} />
      </Wrapper>
    </Container>
  )
}

export default Maktab