import React from 'react';

const Mening = () => {
  return ( 
    <React.Fragment>
      
    </React.Fragment>
   );
}
 
export default Mening;