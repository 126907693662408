import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

// import Images
import DC from "../assets/icons/DC.png"
import Facebook from "../assets/icons/facebook.svg"
import Telegram from "../assets/icons/telegram.svg"
import Instagram from "../assets/icons/instagram.svg"
import Youtube from "../assets/icons/youtube.svg"
import { FooterContainer, LogoFooter } from './style';
import axios from 'axios';
import { BASE_URL } from '../constants/base_url';

class Footer extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    axios.get(`${BASE_URL}/api/v1/admin/info/`)
      .then((res) => {
        const data = res.data.data
        // console.log(data)
        this.setState({
          data: data
        })
      })
  }
  render() {
    var info = this.state.data
    return (
      <FooterContainer>
        <footer className='footer'>
          <div className="footer__left">
            <LogoFooter />
            <p>O‘quvchlar, o‘qituvchilar, abituriyentlar va til o‘rganuvchilar uchun <br />online platforma </p>
          </div>
          <div className="footer__center">
            <a href="/">Bosh sahifa</a>
            <a href="login/chettili">Chet tili</a>
            <a href="login/maktab">Prezident maktabi</a>
            <a href="login/oqituvchi">O‘qituvchilar</a>
            <a href="login/abiturient">Abituriyentlar</a>
          </div>
          <div className="footer__right">
            <div className="dc__logo">
              <img src={DC} alt="" />
              <div>
                <p>DIGITAL</p>
                <p>CITY</p>
              </div>
            </div>
            {/* <form action="">
            <NumberFormat
              placeholder="+998 _ _  _ _"
              format="+998 ## ### ## ##"
              mask="_"
            />
            <button>Ro'yhatdan o'tish</button>
          </form> */}
            <div className="social__sets">
              <a target={"_blank"} href={info?.facebook}><img src={Facebook} alt="" /></a>
              <a target={"_blank"} href={info?.telegram}><img src={Telegram} alt="" /></a>
              <a target={"_blank"} href={info?.instagram}><img src={Instagram} alt="" /></a>
              <a target={"_blank"} href={info?.youtube}><img src={Youtube} alt="" /></a>
            </div>
          </div>
        </footer>
      </FooterContainer>
    );
  }
}

export default Footer;