import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const Login = () => {
  const {  user } = useContext(AuthContext)
  const handleChange = (p) =>{
    localStorage.setItem("maktab", p)
  }
  return (
    <>
    {user?<Navigate to="/kabinet"/>:
    <React.Fragment>
      <div className="login__container">
        <div className="login__box">
          <p>Prezident maktabiga kiruvchilar uchun</p>
          <Link onClick={() => handleChange("1")} to="maktab">Boshlash</Link>
        </div>
        <div className="login__box">
          <p>Abituriyentlar uchun</p>
          <Link onClick={() => handleChange("2")} to="abiturient">Boshlash</Link>
        </div>
        <div className="login__box">
          <p>Chet tili o'rganmoqchilar uchun</p>
          <Link onClick={() => handleChange("2")} to="chettili">Boshlash</Link>
        </div>
        <div className="login__box">
          <p>O'qituvchilar uchun</p>
          <Link onClick={() => handleChange("4")} to="oqituvchi">Boshlash</Link>
        </div>
        <h1>Yo'nalishingizni tanlang</h1>
      </div>
    </React.Fragment>}</>
   );
}
 
export default Login;