import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from '../../../../api/useAxios'
import TestModal from './Modal'
import ResultModal from './Natijalar/Natija'
import { Buttons, Container, InnerContainer, LeftWrap, MarginLeft10, NatijaModal, NumberText, Passing, RightWrap, SavolDiv, StatusTest, TestContanier, TestTitle } from './style'
import Swal from 'sweetalert2'
import { LoadingContainer, LoadingContainerTest } from '../style'


const Test = () => {
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const Base_style = localStorage.getItem('maktab') !== null ? localStorage.getItem('maktab') : '0';
  const [isResultLoading, setisResultLoading] = useState('test');
  const [persentnum, setpersentnum] = useState(0);
  const [TestIndex, setTestIndex] = useState(0)
  const api = useAxios();
  const {id} = useParams();
  const [quizesData, setQuizesData] = useState([]);
  const [loading, setloading] = useState(true);
  const [QuizID, setQuizID] = useState(null);
  const [render, setrender] = useState(false);
  const [QuizHeader, setQuizHeader] = useState('');
  const [Result, setResult] = useState({});
  const [QuizResultsAll, setQuizResultsAll] = useState([]);
  const [QuizTime, setQuizTime] = useState(null);
  const [StartTimerDown, setStartTimerDown] = useState(0);
  const [detail, setdetail] = useState(null)
  

  // console.log("quizes data",quizesData);

  const GetQuizes = async () => {
    try {
      const res = await api.get(`quizs/?course=${id}`)
      if(res.data.success){
        // console.log("tests",res);
        setQuizesData(res.data.data)
        setloading(false)
      }
    } catch (err) {
      alert(err.message);
    }
  }

  const GetCoursesInDetails =  () => {
    try {
      api.get(`/course/detail/${id}/`).then((res)=>{
        if(res.data.success){
          setdetail(res.data.data)
        }
      })
    } catch (error) {
      
    }
}

  const GetResults = async (id) => {
    try {
      const res = await api.get(`/quiz/result/?quiz=${id}`)
      // console.log("res======",res);
      if(res.data.success){
        setQuizResultsAll(res.data.data);
        setModal1Visible(true)

      }
    } catch (err) {
      
    }
  }

  useEffect(() => {
    GetQuizes()
    GetCoursesInDetails()
  }, [render]);


  


  const OpenResultModal = (item) => {
    setQuizID(item.id)
    GetResults(item.id)
  }
//react-countdown

  const OpenQuestionModal = (item) => {
    setQuizID(item.id );
    setQuizHeader(item.section?.title);
    setQuizTime(item.time*60);
    
    if(!item.result?.is_passed){
      setModal2Visible(true)
    }else{
      Swal.fire({
        icon: 'info',
        title: 'Eslatma',
        text: 'Siz testni yechib bo`ldingiz',
        footer: `<a href="">Sizning ko'rsatkichingiz: ${item.result?.mark}%</a>`,
        confirmButtonText:'Tushunarli'
      })
    }
    setrender(!render)
    setisResultLoading('test')
    setResult({});
    setResult({});
    setpersentnum(0)
    setTestIndex(0)
  }

  return (
    <React.Fragment>
       <NatijaModal className='thisiscool'>
       <ResultModal 
          modal2Visible={modal1Visible}
          setModal2Visible={setModal1Visible} 
          QuizResultsAll={QuizResultsAll}
       />
       </NatijaModal>
       <TestModal
          isResultLoading={isResultLoading}
          setisResultLoading={setisResultLoading}
          render={render}
          setrender={setrender}
          QuizID={QuizID}
          modal2Visible={modal2Visible}
          setModal2Visible={setModal2Visible} 
          setResult={setResult}
          Result={Result}
          persentnum={persentnum}
          setpersentnum={setpersentnum}
          TestIndex={TestIndex}
          setTestIndex={setTestIndex}
          QuizHeader={QuizHeader}
          QuizTime={QuizTime}
          setQuizTime={setQuizTime}
          setStartTimerDown={setStartTimerDown}
          StartTimerDown={StartTimerDown}
        />
       <Container>
          {loading ? 
            <LoadingContainerTest>
              Loading ...
            </LoadingContainerTest>
          :
            quizesData?.map((item,i)=>(
              <TestContanier key={i}>
                <InnerContainer>
                  {
                    item.result?.mark === null ? null : <StatusTest me={item.result?.is_passed} baseStyle={Base_style}>{item.result?.is_passed ? "Testdan o'tdi" : "O'ta olmadi"}</StatusTest>
                  }
                  <LeftWrap>
                    <NumberText baseStyle={Base_style}>{item.question_count}</NumberText>
                    <MarginLeft10>
                      <TestTitle>{item.title}</TestTitle>
                      {
                        item.result?.mark === null ? <Passing>O'tish foizi: {item.passed_percent}</Passing>
                        : <Passing>Balingiz: {item.result?.mark}%</Passing>
                      }
                       {
                        item.result?.mark === null ? <SavolDiv baseStyle={Base_style}>Ishlash vaqti: {item.time} minut</SavolDiv>
                        :<SavolDiv baseStyle={Base_style}>Ishlagan vaqtingiz: {item.result?.time} minut</SavolDiv>
                      }
                      
                    </MarginLeft10>
                  </LeftWrap>
                  <RightWrap>
                    <Buttons baseStyle={Base_style} onClick={() => OpenResultModal(item)}>Natijalar</Buttons>
                    <Buttons disabled={true} baseStyle={Base_style} onClick={() => OpenQuestionModal(item)}>Boshlash</Buttons>
                  </RightWrap>
                </InnerContainer>
              </TestContanier>
            ))
          }
       </Container>
    </React.Fragment>
  )
}

export default Test