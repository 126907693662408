import styled from "styled-components";
import BG from '../../../assets/images/abituriyentt.png';
import Bollar from '../../../assets/images/bollar.svg';

export const Container = styled.div`
    margin-top: -100px;
    width: 100%;
    height: 1200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: url(${BG});  
    background-repeat: no-repeat;
    z-index: 88;
    background-color: #FCBC08;
    background-position: center;
    @media (max-width:950px){
        height: 800px;
    }
    `
export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1920px;
    background-repeat:no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    z-index: 88;
    @media (max-width:821px){
        padding: 15px;
        box-sizing: border-box;
    }
`


export const PupilBox = styled.div`
    width: 60%;
    height: 50%;
    background: url(${Bollar});
    background-repeat:no-repeat;
    background-size: contain;
    display: flex;
    background-position: center center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    @media (max-width:1400px){
        width: 65%;
    }
    @media (max-width:1111px){
        height: 35%;
    }
    @media (max-width:821px){
        height: 35%;
        width: 100%;
        background-position: left center ;
    }
    
   
`
export const TextDiv = styled.div`
    /* background-color: aqua; */
    width: 70%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    @media (max-width:1111px){
        height: 200px;
    }
    @media (max-width:821px){
        height: 150px;
    }
    @media (max-width:500px){
       height: 100px;
    }
`
export const BR = styled.br`

`
export const Title = styled.div`
    font-family: 'Lexend';
    font-size: 60px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    @media (max-width:1450px){
        font-size: 50px;
        line-height: 50px;
    }
    
    @media (max-width:1111px){
        font-size: 35px;
        line-height: 50px;
    }
    @media (max-width:821px){
        font-size: 25px;
        line-height: 30px;
    }
    @media (max-width:500px){
       font-size: 17px;
       line-height: 20px;
    }
`
export const LittleTitle = styled.div`
    font-family: 'Lexend';
    font-size: 36px;
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    @media (max-width:1450px){
        font-size: 30px;
        line-height: 50px;
    }
    @media (max-width:1300px){
        font-size: 25px;

        line-height: 50px;
    }
    @media (max-width:1111px){
        font-size: 20px;
        line-height: 50px;
    }
    @media (max-width:821px){
        font-size: 14px;
        line-height: 30px;
    }
    @media (max-width:500px){
       font-size: 10px;
       line-height: 20px;
    }
`
export const Batafsil = styled.a`
    background: #3e3fc4;
    font-size: 24px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    height: 62px;
    width: 214px;
    position: relative;
    border-radius: 7.6715240478515625px;
    margin-top: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: 460px) {
        font-size: 19px;
        line-height: 30px;
        height: 42px;
        width: 184px;
    }

    :before {
        content: "";
        background: linear-gradient(
        45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #34f5e2,
        #4a4a4a,
        #ff00c8,
        #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
    }
    :active {
        color: #00fff7;
    }

    :active:after {
        background: transparent;
    }

    :hover:before {
        opacity: 1;
    }

    :after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    @keyframes glowing {
        0% {
        background-position: 0 0;
        }
        50% {
        background-position: 400% 0;
        }
        100% {
        background-position: 0 0;
        }
    }
    @media (max-width:1300px){
        width: 200px;
        height: 50px;
        font-size: 20px;
    }
    @media (max-width:830px){
        width: 150px;
        height: 40px;
        font-size: 17px;
    }
    @media (max-width:500px){
        width: 100px;
        height: 33px;
        font-size: 14px;
    }
`

//animated images

export const BagImg = styled.img`
    position: absolute;
    bottom: 3%;
    right: 2%;
    max-width: 500px;
    width: 500px;
    @media (max-width:1350px){
        width: 300px;
    }
    @media (max-width:911px){
        width: 250px;
        
    }
    @media (max-width:650px){
        width: 200px;
    }
    @media (max-width:500px){
       width: 100px;
    }
`
export const BookImg = styled.img`
    position: absolute;
    top: 6%;
    left: 15%;
    max-width: 500px;
    @media (max-width:1650px){
        width: 200px;
        left: 10%;
    }
    @media (max-width:911px){
        width: 100px;
        
    }
`
export const NoteBookImg = styled.img`
    position: absolute;
    top: 30%;
    left: 5%;
    max-width: 500px;
    @media (max-width:1350px){
        width: 100px;
        left: 3%;
    }
    @media (max-width:911px){
        width: 70px;
        
    }
    @media (max-width:650px){
        top: 40%;
    }
    @media (max-width:500px){
       top: 35%;
    }
`
export const ClockImg = styled.img`
    position: absolute;
    top: 27%;
    left: 13%;
    max-width: 500px;
    @media (max-width:1350px){
        width: 150px;
        left: 12%;
    }
    @media (max-width:1350px){
        width: 150px;
        left: 15%;
    }
    @media (max-width:911px){
        width: 100px;
        
    }
    @media (max-width:650px){
        top: 50%;
    }
    @media (max-width:500px){
       width: 80px;
       left: 30%;
    }
`

export const SaturnImg = styled.img`
    position: absolute;
    top: 15%;
    left:30%;
    max-width: 500px;
    @media (max-width:911px){
        width: 150px;
        
    }
    @media (max-width:500px){
       width: 80px;
    }
`

export const ChizgichImg = styled.img`
    position: absolute;
    top: 37%;
    left:40%;
    max-width: 500px;
    @media (max-width:1350px){
        width: 150px;
        left: 35%;
    }
    @media (max-width:500px){
       width: 80px;
       left: 50%;
    }
`
export const RoketImg = styled.img`
    position: absolute;
    top: 15%;
    right:15%;
    max-width: 500px;
    @media (max-width:1550px){
        width: 400px;
        right: 10%;
    }
    @media (max-width:1350px){
        width: 300px;
        right: 10%;
    }
    @media (max-width:1111px){
        width: 300px;
        right: 5%;
    }
    @media (max-width:911px){
        width: 200px;
        right: 0%;
    }
    @media (max-width:650px){
        display: none;
    }
`