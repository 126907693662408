import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../constants/base_url';

const Search = ({ data, setSearchWord }) => {
  const handleClear = () => setSearchWord("");
  const status = localStorage.getItem("maktab")
  const num = status === "1" ? "#F17120"
    : status === "2" ? "#0BA7BF"
      : status === "3" ? "#0BA7BF"
        : "#1D794B"
  return (
    <React.Fragment>
      <div className="search__results" style={{ "--i": `${num}` }}>
        {data?.map((item, i) => {
          return (
            <Link to={`/kabinet/lesson/${item.id}/`} key={i} onClick={handleClear}>
              <img src={BASE_URL + item.image} alt="" />
              {item.title}
            </Link>
          )
        })}
      </div>
    </React.Fragment>
  );
}

export default Search;