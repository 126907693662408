import React, { useEffect } from 'react';
import PrivateRoute from './utils/PrivateRoute';
import NoMatch from './components/404/NoMatch';
import { AuthProvider } from './context/AuthContext';
import { Routes, Route, Outlet } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.min.css'
import "./style/css/_Import.css"
//Home Companents 
import HomeScreen from './page/HomePage/HomePage';
import HomeScroll from './page/HomePage/HomeScroll';
//Login Companents
import Login from './page/Login/Login';
import Maktab from './page/Login/Maktab/Maktab';
import Chettili from './page/Login/Chettili/Chettili';
import Oqituvchi from './page/Login/Oqituvchi/Oqituvchi';
import Abituriyent from './page/Login/Abiturient/Abiturient';
//Kabinet Companents
import Kabinet from './page/KabinetStudent/Kabinet';
import Kurslar from './page/KabinetStudent/Kurslar/Kurslar';
import Taxrirlash from './page/KabinetStudent/Taxrirlash/Taxrirlash';
import KabinetHome from './page/KabinetStudent/KabinetHome/KabinetHome';
//
import Izoh from './page/KabinetStudent/VideoLesson/Izoh/Izoh';
import Test from './page/KabinetStudent/VideoLesson/Test/Testss';
import Manba from './page/KabinetStudent/VideoLesson/Manba/Manba';
import Tavsif from './page/KabinetStudent/VideoLesson/Tavsif/Tavsif';
import VideoLesson from './page/KabinetStudent/VideoLesson/VideoLesson';
//
import Mening from "./page/KabinetStudent/Saqlangan/Mening";
import Korilgan from "./page/KabinetStudent/Saqlangan/Korilgan";
import Barchasi from "./page/KabinetStudent/Saqlangan/Barchasi";
import Saqlangan from './page/KabinetStudent/Saqlangan/Saqlanga';

import Loading from "./components/Loadings/loading"

import DisableDevtool from 'disable-devtool';

const App = () => {
  const width = window.screen.width
  DisableDevtool();

  const Home = width < 769 ? <HomeScreen /> : <HomeScroll />
  useEffect(() => {

  }, [width])
  return (
    <React.Fragment>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          {/* <Route path='/scroll' element={<HomeScreen />}/> */}
          <Route path='*' element={<NoMatch />} />
          <Route path='/loading' element={<Loading />} />

          <Route path='login' element={<Outlet />}>
            <Route path='' element={<Login />} />
            <Route path='maktab' element={<Maktab />} />
            <Route path='chettili' element={<Chettili />} />
            <Route path='oqituvchi' element={<Oqituvchi />} />
            <Route path='abiturient' element={<Abituriyent />} />
          </Route>

          <Route path='kabinet' element={<PrivateRoute><Kabinet /></PrivateRoute>}>
            <Route path='' element={<KabinetHome />} />
            <Route path='kurslar' element={<Kurslar />} />
            <Route path='taxrirlash' element={<Taxrirlash />} />

            <Route path='lesson/:id' element={<VideoLesson />}>
              <Route path='' element={<Tavsif />} />
              <Route path='izoh' element={<Izoh />} />
              <Route path='test' element={<Test />} />
              <Route path='manba' element={<Manba />} />
            </Route>

            <Route path='saved' element={<Saqlangan />}>
              <Route path='' element={<Barchasi />} />
              <Route path='my' element={<Mening />} />
              <Route path='views' element={<Korilgan />} />
            </Route>

          </Route>

        </Routes>
      </AuthProvider>
    </React.Fragment>

  );
}

export default App;