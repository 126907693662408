import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

const Saqlangan = () => {
  const [linkActive , setLinkActive] = useState(1)
  const [mode] = useState(window.localStorage.getItem('maktab'));
  return ( 
    <React.Fragment>
      <div className="saqlangan__wrapper">
        <div className="saqlangan">
          <h2>Mening darsliklarim</h2>
          <div className="saqlanganlar__list">
          <Link className={linkActive === 1 ? `link_Active${mode}`:null} to="" onClick={()=> setLinkActive(1)}>Barcha saqlanganlar</Link>
          </div>
        </div>
      </div>
      <Outlet/>
    </React.Fragment>
   );
}
 
export default Saqlangan;