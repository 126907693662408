import React from 'react'
import { WavesShape } from './style'

const Waves = () => {
  return (
    <WavesShape>
      
      </WavesShape>
  )
}

export default Waves