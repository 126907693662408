import styled from "styled-components"


export const WavesShape = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1B013A;
    height: 200px;
    z-index: 10;
    position: static;
    clip-path: polygon(100% 0%, 0% 0% , 0% 96.97%, 1% 96.91%, 2% 96.74%, 3% 96.45%, 4% 96.05%, 5% 95.54%, 6% 94.91%, 7% 94.19%, 8% 93.36%, 9% 92.43%, 10% 91.41%, 11% 90.31%, 12% 89.12%, 13% 87.86%, 14% 86.52%, 15% 85.13%, 16% 83.67%, 17% 82.17%, 18% 80.63%, 19% 79.06%, 20% 77.46%, 21% 75.85%, 22% 74.23%, 23% 72.61%, 24% 70.99%, 25% 69.40%, 26% 67.82%, 27% 66.28%, 28% 64.78%, 29% 63.33%, 30% 61.94%, 31% 60.61%, 32% 59.34%, 33% 58.16%, 34% 57.05%, 35% 56.03%, 36% 55.11%, 37% 54.28%, 38% 53.56%, 39% 52.94%, 40% 52.43%, 41% 52.03%, 42% 51.74%, 43% 51.57%, 44% 51.52%, 45% 51.58%, 46% 51.75%, 47% 52.04%, 48% 52.44%, 49% 52.96%, 50% 53.58%, 51% 54.31%, 52% 55.14%, 53% 56.07%, 54% 57.09%, 55% 58.20%, 56% 59.39%, 57% 60.65%, 58% 61.99%, 59% 63.38%, 60% 64.84%, 61% 66.34%, 62% 67.88%, 63% 69.45%, 64% 71.05%, 65% 72.66%, 66% 74.29%, 67% 75.91%, 68% 77.52%, 69% 79.12%, 70% 80.69%, 71% 82.23%, 72% 83.73%, 73% 85.18%, 74% 86.57%, 75% 87.90%, 76% 89.16%, 77% 90.35%, 78% 91.45%, 79% 92.47%, 80% 93.39%, 81% 94.21%, 82% 94.94%, 83% 95.56%, 84% 96.06%, 85% 96.46%, 86% 96.75%, 87% 96.92%, 88% 96.97%, 89% 96.91%, 90% 96.73%, 91% 96.44%, 92% 96.03%, 93% 95.52%, 94% 94.89%, 95% 94.16%, 96% 93.33%, 97% 92.40%, 98% 91.38%, 99% 90.27%, 100% 89.08%);
    @media (max-width:1000px){
        display: none;
    }
`