import React, { useEffect, useState } from "react";
import videojs from "video.js";
import { Button, Dropdown, Menu } from 'antd';
import { Container, Dropdownme, IconBtn, VideoWrapper } from "./style";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import VideoSettingsIcon from '@mui/icons-material/Settings';

function VideoJs({video_url,show,setshow}) {
  let player = React.useRef(null);
  let refVideoEl = React.useRef(null);
  let [start, setStart] = React.useState(null);
  let [end, setEnd] = React.useState(null);
  const [whispiedSpeed, setwhispiedSpeed] = useState(0);
  const [render, setrender] = useState(false);
  
  
  // console.log("Internet speed of test", whispiedSpeed);
  function QualityFixHandle(speed) {
    setwhispiedSpeed(speed);
  }

  function ChangeHandle() {
    console.log('Waiting Callbakc ...')
    if(whispiedSpeed>=20){
      console.log('1080 is playing')
    }else if(whispiedSpeed>=13 && whispiedSpeed<20){
      console.log('720 is playing')
    }else if(whispiedSpeed>=8 && whispiedSpeed<13){
      console.log('480 is playing')
    }else{
      console.log('360 is playing')
    }
  }
  
  
  function handleSetStart() {
    setStart(player.current.currentTime());
  }
  
  // console.log(refVideoEl)
  

  function handleSetEnd() {
    setEnd(player.current.currentTime());
  }

  function handlePreviousFrame() {
    player.current.currentTime(player.current.currentTime() - 10);
  }

  function handleNextFrame() {
    player.current.currentTime(player.current.currentTime() + 10);
  }

  // Init it once
  React.useEffect(() => {
    player.current = videojs(refVideoEl.current);
  }, [player]);


  const menu = (
    <Menu
    style={{minWidth:150,}}
      items={[
        {
          key: '0',
          label: (
            <a rel="noopener noreferrer" href="#">
              auto (automatic)
            </a>
          ),
        },
        {
          key: '1',
          label: (
            <a rel="noopener noreferrer" href="#">
              1080p ( HD )
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a rel="noopener noreferrer" href="#">
              720p ( Normal )
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a rel="noopener noreferrer" href="#">
              480p ( Good  )
            </a>
          ),
        },
        {
          key: '4',
          label: (
            <a rel="noopener noreferrer" href="#">
              360p ( Low )
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <Container>
      <VideoWrapper
        className="video-js vjs-default-skin player-video-js"
        controls
        id="videojs"
        // playsInline
        preload="auto"
        ref={refVideoEl}
        autoPlay={show}
        src={video_url}
        onError={()=>console.log('Error Callback here ...')}
        onPause={()=>console.log('Pause Callback here ...')}
        onPlaying={()=>console.log('Playing ....')}
        onWaiting={()=>ChangeHandle()}
        onEnded={()=>setshow(false)}
      >
       <source src={video_url}  />
      </VideoWrapper>
      
      <Dropdownme overlay={menu} placement="bottomRight" arrow>
      <IconBtn>
          <VideoSettingsIcon style={{color:'white'}}  />
      </IconBtn>
      </Dropdownme>
      {/* <br />
      <br />
      <div>
        Start: {start}, End: {end}
      </div>
      <br />
      <button onClick={handlePreviousFrame}>previous frame</button>&nbsp;
      <button onClick={     }>next frame</button>&nbsp;
      <button onClick={handleSetStart}>set start point</button>&nbsp;
      <button onClick={handleSetEnd}>set end point</button> */}
       <ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={null}
            txtMainHeading="Opps..." 
            pingInterval={4000} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={100}
            imageUrl="https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?cs=srgb&dl=pexels-pixabay-36717.jpg&fm=jpg"
            downloadSize="1781287"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>QualityFixHandle(speed*1)}
            callbackFunctionOnNetworkTest={(speed)=>QualityFixHandle(speed*1)}
          />
    </Container>
  );
}

export default VideoJs;
